import { Flex, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BaseModal from "src/components/modals/BaseModal";
import { PayoutOption } from "src/redux/Payout/reducer";
import Lottie from "lottie-react";
import CashAnimation from "../../assets/animations/cash.json";
import { t } from "i18next";
import GradientButton from "src/components/GradientButton";
import { CloseNotificationAction } from "src/redux/Notification/action";
import { RequestPayoutAction } from "src/redux/Payout/action";

export const PAYOUT_REQUEST_TYPE = "payout_request";

function PayoutRequestModal(props: { params: { option: PayoutOption } }) {
  const dispatch = useDispatch();

  const option = props.params.option;
  const placeholderText =
    option.category === "paypal" ? t("payout.pay_pal_mail") : t("payout.mail");
  const [mail, setMail] = useState<string>("");

  return (
    <BaseModal>
      <Flex
        flexDir={"column"}
        p={4}
        alignItems="center"
        flex={1}
        justifyContent="center"
        bgGradient={`linear(to-tr, #4000ff, #7B00FF, #7B00FF)`}
      >
        <Lottie
          animationData={CashAnimation}
          style={{ height: 120 }}
          autoPlay={true}
        />

        <Flex flex={1} flexDir="column" justifyContent={"center"}>
          <Text
            noOfLines={1}
            fontWeight="bold"
            textAlign={"center"}
            color="white"
            fontSize={24}
            /* style={styles.moneyAmountText} */
          >
            {option.currency}
            {option.value.toFixed(2)}
          </Text>
          <Text
            noOfLines={2}
            fontWeight="bold"
            textAlign={"center"}
            color="white"
            fontSize={18}
            /* style={styles.payoutRequestTitleText} */
          >
            {t("payout.request_title")}
          </Text>
          <Text
            noOfLines={1}
            fontWeight="bold"
            textAlign={"center"}
            color="white"
            fontSize={12}
            mt={1}
            /* style={styles.payoutReceiveTimeText} */
          >
            {t("payout.receive_time")}
          </Text>

          <Input
            type={"email"}
            value={mail}
            placeholder={placeholderText}
            onChange={(e) => setMail(e.target.value)}
            fontWeight="normal"
            color="gray.700"
            fontSize={16}
            borderRadius={10}
            backgroundColor={mail.length < 0 ? "whiteAlpha.800" : "white"}
            mt={4}
            /* style={styles.mailInput} */
          />
        </Flex>

        <Flex w={"full"}>
          <GradientButton
            animate
            disabled={!mail.includes("@")}
            onClick={() => {
              dispatch(CloseNotificationAction());
              dispatch(
                RequestPayoutAction(
                  option.value,
                  option.currency,
                  option.coins,
                  option.category,
                  mail
                )
              );
            }}
          >
            <Text color={"white"}>{t("request")}</Text>
          </GradientButton>
        </Flex>
      </Flex>
    </BaseModal>
  );
}

export default PayoutRequestModal;
