import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { PURPLE } from "src/constants/colors";
import useCustomSizing from "src/hooks/useCustomSizing";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const ScanQRMessage = () => {
  const { getWidthInPixels, getHeightInPixels } = useCustomSizing();
  return (
    <Flex
      flex={1}
      flexDir="column"
      background={PURPLE}
      alignItems="center"
      justify={"center"}
      px={getWidthInPixels(8)}
    >
      <Flex
        boxSize={getHeightInPixels(32)}
        borderRadius="2xl"
        backgroundColor={"white"}
        p={getHeightInPixels(4)}
        alignItems="center"
        justify={"center"}
      >
        <QRCode value={window.location.href} />
      </Flex>
      <Text
        mt={getHeightInPixels(6)}
        fontSize={getHeightInPixels(4)}
        textAlign="center"
        color={"white"}
        fontWeight="bold"
      >
        {t("scanQr")}
      </Text>
      <Text
        pos={"absolute"}
        bottom={getHeightInPixels(4)}
        align="center"
        textAlign={"center"}
        color="white"
        textDecorationLine={"underline"}
        fontWeight="bold"
      >
        <Link to="/imprint">{t("imprint")}</Link>
      </Text>
    </Flex>
  );
};

export default ScanQRMessage;
