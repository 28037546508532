import { useEffect, useState } from "react";

/* import analytics from "@react-native-firebase/analytics";
import messaging from "@react-native-firebase/messaging"; */
// @ts-ignore
/* import { IDFA } from "aia-react-native-idfa";
import DeviceInfo from "react-native-device-info";
import * as RNLocalize from "react-native-localize"; */
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "src/redux";
/* import { RootReducer } from "@root/_redux/RootReducer"; */
/* import { LogException } from "@utils/logging"; */
import { FetchUserAction } from "src/redux/UserCreate/action";
import { UserBaseData } from "src/types/User";
import { v4 as uuidv4 } from "uuid";

export const useInitUser = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootReducer) => state.user.userId);

  useEffect(() => {
    if (userId) {
      return;
    }

    GatherUserInfo()
      .then((userInfo: UserBaseData) => {
        dispatch(FetchUserAction(userInfo));
      })
      .catch((e: any) => {
        console.log("fail", e);
        // TODO here it would be nice to have some retry logic
        /* LogException(e); */
      });
  }, [dispatch, userId]);
};

export const GatherUserInfo = async (): Promise<UserBaseData> => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const ip = await getIP();
  const deviceId = getDeviceId();
  const deviceType = getDeviceType(window.navigator.userAgent);
  const { countryCode, language } = getCountryCodeAndLanguage();

  const manufacturer = navigator.userAgent;
  const userAgent = navigator.userAgent;

  const { os, osLevel, deviceModel, hardware, apiLevel } =
    getDeviceInformations(userAgent);

  const platform = os.toLowerCase().includes("ios") ? "web_ios" : "web_android";

  return {
    advertiserId: null,
    appId: "playtime.web",
    platform: platform,
    apiLevel: apiLevel ? parseFloat(apiLevel) : parseInt(osLevel), //TODO: get from Device.osVersion
    buildNumber: "1",
    countryCode: countryCode,
    deviceId: deviceId,
    nextDeviceId: deviceId,
    appSetId: null,
    deviceType: deviceType,
    deviceName: /* Device.deviceName ? Device.deviceName : */ deviceModel,
    hardware: hardware, //window.navigator.userAgent
    ipAddress: ip,
    languageCode: /* locale.languageCode */ language,
    manufacturer: manufacturer,
    product: /* Device.modelName ? Device.modelName : */ "", // window.navigator.userAgent
    timezone: timeZone,
    fcmToken: "", // fcm.ts handles this
    isUsagePermissionGranted: false,
    isDeviceRooted: false,
  };
};

const getCountryCodeAndLanguage = (): {
  countryCode: string;
  language: string;
} => {
  //TODO: get country code
  const langCode =
    window.navigator.languages[window.navigator.languages.length - 1];

  return {
    countryCode: "US",
    language: langCode.slice(0, 2),
  };
};

const getDeviceId = (): string => {
  const deviceId = localStorage.getItem("playtimeId");
  if (deviceId) {
    return deviceId;
  }

  let generatedId = uuidv4().toString();
  localStorage.setItem("playtimeId", generatedId);

  return generatedId;
};

function getDeviceType(userAgent: string) {
  const tempUserAgent = userAgent.toLowerCase();
  if (/mobile/i.test(tempUserAgent)) {
    return "Mobile";
  } else if (/tablet/i.test(tempUserAgent)) {
    return "Tablet";
  } else if (/iPad/i.test(tempUserAgent)) {
    return "iPad";
  } else {
    return "Desktop/Laptop";
  }
}

const API_LEVELS = {
  "14": 34,
  "13": 33,
  "12L": 32,
  "12": 31,
  "11": 30,
  "10": 29,
  "9": 28,
  "8.1": 27,
  "8.0": 26,
  "7.1": 25,
  "7.0": 24,
  "6": 23,
  "5.1": 22,
  "5.0": 21,
  "4": 20,
};

export const getDeviceInformations = (userAgent: string) => {
  const ua = userAgent;
  const iosRegex = /(iPhone|iPad|iPod)\sOS\s([\d_]+)/i;
  const androidRegex =
    /Android\s([\d\.]+[a-zA-Z]?)(?:;|\s)(?:[a-zA-Z]{2}-[a-zA-Z]{2};\s)?(.*?)(?:\sBuild|\)|;|$)/i;

  const macRegex = /Macintosh.+Mac OS X\s([\d_]+)/i;
  const windowsRegex = /Windows\sNT\s([\d.]+)/i;
  const iPadRegex = /iPad.+OS\s([\d_]+)/i;

  let os = "";
  let version = "";
  let model = "";
  let osLevel = "";
  let apiLevel = "";

  if (iosRegex.test(ua)) {
    const match = ua.match(iosRegex);
    os = "iOS";
    version = match ? match[2].replace(/_/g, ".") : "";
    const modelMatch = ua.match(/\b(iPhone|iPad|iPod)\b/);
    model = modelMatch && modelMatch[1] ? modelMatch[1] : "";
    osLevel = version.split(".")[0];
    apiLevel = osLevel;
  } else if (androidRegex.test(ua)) {
    const match = ua.match(androidRegex);
    os = "Android";
    version = match ? match[1] : "";
    model = match ? match[2] : "";
    osLevel = version.split(".")[0];

    let fixedVersion = parseInt(version).toFixed(1);
    if (version in API_LEVELS) {
      //@ts-ignore
      apiLevel = API_LEVELS[version];
    } else if (fixedVersion in API_LEVELS) {
      //@ts-ignore
      apiLevel = API_LEVELS[fixedVersion];
    }
  } else if (macRegex.test(ua)) {
    const match = ua.match(macRegex);
    os = "macOS";
    version = match ? match[1].replace(/_/g, ".") : "";
    osLevel = version.split(".")[0];
    apiLevel = osLevel;
  } else if (windowsRegex.test(ua)) {
    const match = ua.match(windowsRegex);
    os = "Windows";
    version = match ? match[1] : "";
    osLevel = version.split(".")[0];
    apiLevel = osLevel;
  } else if (iPadRegex.test(ua)) {
    const match = ua.match(iPadRegex);
    os = "iOS";
    version = match ? match[1].replace(/_/g, ".") : "";
    model = "iPad";
    osLevel = version.split(".")[0];
    apiLevel = osLevel;
  } else {
    os = "Unknown OS";
  }

  /* console.log(`Device OS: ${os} ${version}`);
  console.log(`Device Model: ${model}`);
  console.log(`OS Level: ${osLevel}`);
  console.log(`hardware: ${model} ${os} ${version}`);
  console.log(`API Level: ${apiLevel}`); */

  return {
    os,
    osLevel,
    deviceModel: model,
    version,
    hardware: `${model} ${os} ${version}`.trim(),
    apiLevel,
  };
};

const getIP = async () => {
  try {
    const res = await fetch("https://api.ipify.org?format=json", {
      method: "GET",
    });
    const json = await res.json();
    return json.ip;
  } catch (error) {
    console.log("failed ip: ", error);
    return "";
  }
};
