import moment from "moment";
import { RequestStatus } from "src/types/Request";

export interface UserState {
  userId: number | null;
  userUuid: string;
  userIsAnon: boolean;
  wallet: UserWallet | null;
  isUsagePermissionGranted: boolean;
  isNotificationPermissionGranted: boolean;
  showOfferInstallStep: boolean;
  showCoinsPage: boolean;
  userInfo: UserInfo;
  userAnonFetchRequestState: RequestStatus;
}

export interface UserWallet {
  coins: number;
}

export const initialState: UserState = {
  userId: null,
  userUuid: "",
  userIsAnon: true,
  wallet: null,
  isUsagePermissionGranted: false,
  isNotificationPermissionGranted: false,
  showOfferInstallStep: true,
  showCoinsPage: true,
  userInfo: {
    name: "",
    gender: "",
    age: 0,
    dayOfBirth: "",
    createdAt: moment(),
    id: 0,
  },
  userAnonFetchRequestState: {
    isLoading: false,
    isSuccessful: false,
    isFailed: false,
  },
};

export function User(state = initialState, action: any) {
  switch (action.type) {
    case "user-update-user-init-info":
      return {
        ...state,
        userId: action.userInitInfo.userId,
        userUuid: action.userInitInfo.userUuid,
        userIsAnon: action.userInitInfo.isAnon,
        isUsagePermissionGranted: action.userInitInfo.isUsagePermissionGranted,
        showOfferInstallStep: action.userInitInfo.showOfferInstallStep,
        showCoinsPage: action.userInitInfo.showCoinsPage,
        userInfo: {
          id: action.userInitInfo.userId,
          name: action.userInitInfo.name,
          age: action.userInitInfo.age,
          dayOfBirth: action.userInitInfo.dayOfBirth,
          description: action.userInitInfo.description,
          images: action.userInitInfo.images,
          gender: action.userInitInfo.gender,
          targetGenders: action.userInitInfo.targetGenders,
          createdAt: action.userInitInfo.createdAt,
        },
      };
    case "user-update-user-info":
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "user-update-wallet":
      return {
        ...state,
        wallet: action.wallet,
      };
    case "user-update-notification-permission-granted":
      return {
        ...state,
        isNotificationPermissionGranted: action.isNotificationPermissionGranted,
      };
    case "user-update-set-to-anon":
      return {
        ...state,
        userIsAnon: true,
      };
    case "user-update-fetch-user-request-state":
      return {
        ...state,
        userAnonFetchRequestState: action.userAnonFetchRequestState,
      };
    case "user-clear":
      return initialState;
    default:
      return state;
  }
}

export interface UserInfo {
  id: number | string;
  name: string;
  age: number;
  dayOfBirth: string;
  createdAt: moment.Moment;
  gender: string;
}

export interface InstalledApp {
  firstInstallTime: string;
  packageName: string;
}
