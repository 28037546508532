import { Route, Routes } from "react-router-dom";
import AppState from "src/listeners/AppState";
import AppLayout from "src/layouts/AppLayout";
import { AppRoutes } from "src/constants/routes";
import ModalListener from "src/listeners/ModalListener";
import FcmListener from "src/listeners/FCM";
import Imprint from "src/pages/Imprint";

function AppRouter() {
  return (
    <>
      <FcmListener />
      <ModalListener />
      <AppState />

      <Routes>
        {AppRoutes.map((route) => (
          <Route
            path={route.path}
            element={
              <AppLayout>
                <route.component />
              </AppLayout>
            }
            key={route.id}
          />
        ))}
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
    </>
  );
}

export default AppRouter;
