import {Flex, Text} from "@chakra-ui/react";
import {isSupported} from "firebase/messaging";
import {t} from "i18next";
import {useDispatch} from "react-redux";
import GradientButton from "src/components/GradientButton";
import BaseModal from "src/components/modals/BaseModal";
import useCustomSizing from "src/hooks/useCustomSizing";
import {publish} from "src/listeners/ModalEvents";
import {UpdateIsNotificationPermissionGrantedAction} from "src/redux/User/action";
import usePWA from 'react-pwa-install-prompt'

export const NOTIFICATION_PERMISSION_REQUEST_TYPE =
    "notification_permission_request";

const NotificationPermissionModal = (params: any) => {
    const {getHeightInPixels} = useCustomSizing();
    const {isInstallPromptSupported, promptInstall} = usePWA()

    const dispatch = useDispatch();

    const handleGetNotificationPermissionRequest = async () => {
        const notificationSupported = await isSupported();
        if (!notificationSupported) {
            //notifications not supported
            dispatch(UpdateIsNotificationPermissionGrantedAction(true));
            publish("closeModal", NOTIFICATION_PERMISSION_REQUEST_TYPE);
            return;
        }

        navigator.serviceWorker
            .register("firebase-messaging-sw.js")
            .then(async (register) => {
                const permissionResult = await Notification.requestPermission();
                if (permissionResult === "granted") {
                    dispatch(UpdateIsNotificationPermissionGrantedAction(true));
                }
                if (permissionResult === "denied") {
                    //if user has denied too often we don't want to block them for now
                    dispatch(UpdateIsNotificationPermissionGrantedAction(true));
                }
                publish("closeModal", NOTIFICATION_PERMISSION_REQUEST_TYPE);
                setTimeout(() => {
                    params.params.callback && params.params.callback();
                }, 500);
            });
    };

    return (
        <BaseModal headerImage="bg_request.png">
            <Flex
                flexDir={"column"}
                p={getHeightInPixels(4)}
                alignItems="center"
                flex={1}
                justifyContent="center"
                bgGradient={`linear(to-tr, #4000ff, #7B00FF, #7B00FF)`}
            >
                <Flex flex={1} flexDir="column" justifyContent={"center"}>
                    <Text
                        textAlign={"center"}
                        color={"white"}
                        fontSize={getHeightInPixels(4)}
                        fontWeight="black"
                        noOfLines={2}
                    >
                        {t("notification_permission_request.permission_required")}
                    </Text>
                    <Text
                        mt={getHeightInPixels(2)}
                        mb={getHeightInPixels(1)}
                        textAlign={"center"}
                        color={"white"}
                        fontSize={getHeightInPixels(3)}
                        fontWeight="medium"
                    >
                        {t("notification_permission_request.description")}
                    </Text>
                </Flex>

                <Flex mt={6} w="100%">
                    <GradientButton
                        animate
                        onClick={async () => {
                            if (isInstallPromptSupported) {
                                await promptInstall();
                            }

                            await handleGetNotificationPermissionRequest();
                        }}
                    >
                        <Text
                            fontWeight={"semibold"}
                            fontSize={getHeightInPixels(3)}
                            textTransform="uppercase"
                        >
                            {t("notification_permission_request.confirm")}
                        </Text>
                    </GradientButton>
                </Flex>
            </Flex>
        </BaseModal>
    );
};

export default NotificationPermissionModal;
