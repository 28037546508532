import { call, put, takeLatest } from "redux-saga/effects";

import { PayoutOptions, Payout } from "src/constants/endpoints";
import {
  RequestFailedUpdate,
  RequestLoadingUpdate,
  RequestSuccessfulUpdate,
} from "src/constants/RequestStatus";
import {
  UpdateFetchPayoutOptionsRequestState,
  UpdatePayoutOptions,
} from "src/redux/Payout/action";
import { TransformFetchPayoutOptionsRequestState } from "src/redux/Payout/transformer";
import { FetchPayoutHistory } from "src/redux/PayoutHistory/action";
/* import { FetchPayoutHistory } from "@root/_redux/PayoutHistory/action"; */
import { FetchWalletAction } from "src/redux/User/action";
import { Post } from "src/utils/api";

export function* payoutSaga() {
  yield takeLatest("payout-options-fetch", fetchPayoutOptions);
  yield takeLatest("payout-persist-payout-request", persistPayoutRequest);
}

function* fetchPayoutOptions() {
  try {
    yield put(UpdateFetchPayoutOptionsRequestState(RequestLoadingUpdate));

    // @ts-ignore
    const response = yield call(Post, PayoutOptions);

    yield put(
      UpdatePayoutOptions(TransformFetchPayoutOptionsRequestState(response))
    );

    yield put(UpdateFetchPayoutOptionsRequestState(RequestSuccessfulUpdate));
  } catch (e) {
    yield put(UpdateFetchPayoutOptionsRequestState(RequestFailedUpdate));
  }
}

function* persistPayoutRequest(action: any) {
  try {
    // @ts-ignore
    yield call(Post, Payout, {
      value: action.value,
      currency: action.currency,
      coins: action.coins,
      mail: action.mail,
      category: action.category,
    });

    yield put(FetchWalletAction());
    yield put(FetchPayoutHistory());
  } catch (e) {}
}
