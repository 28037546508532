import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import GradientButton from "src/components/GradientButton";
import useCustomSizing from "src/hooks/useCustomSizing";
import { CloseNotificationAction } from "src/redux/Notification/action";
import { PersistOfferClickAction } from "src/redux/Offers/action";
import { Offer } from "src/redux/Offers/reducer";
import {getDeviceInformations} from "../../hooks/User";

export const OFFER_ON_TOP_TYPE = "offer_on_top";

const OfferOnTopModal = ({ params }: { params: any }) => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const dispatch = useDispatch();
  const offerOnTop: Offer = { ...params };
  const { onClose } = useDisclosure();
  const deviceInfo = getDeviceInformations(navigator.userAgent);
  const isAndroidStore = deviceInfo.os === "Android";

  const handleClose = () => {
    onClose();
    dispatch(CloseNotificationAction());
  };

  return (
    <Modal isOpen={true} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={6} maxH={getHeightInPixels(90)} borderRadius={10}>
        <ModalHeader
          alignItems={"center"}
          justifyContent="center"
          bgGradient={"linear(to-r, #7B00FF, #7B00FF, #4000ff)"}
          borderTopLeftRadius={10}
          borderTopRightRadius={10}
          h={getHeightInPixels(8)}
          pos="relative"
        >
          <Heading
            color={"white"}
            fontSize={getHeightInPixels(3)}
            textAlign="center"
            fontWeight={"medium"}
          >
            {t("offer.install")}
          </Heading>
          <CloseIcon
            onClick={handleClose}
            pos={"absolute"}
            right={getWidthInPixels(4)}
            top={getHeightInPixels(2.5)}
            color="white"
            boxSize={getHeightInPixels(2.5)}
          />
        </ModalHeader>
        <Flex
          flexDir={"column"}
          p={getWidthInPixels(4)}
          alignItems="center"
          flex={1}
        >
          <Flex
            flex={1}
            justifyContent="center"
            alignItems="center"
            flexDir={"column"}
          >
            <Image
              src={offerOnTop?.iconUrl}
              borderRadius={getHeightInPixels(2)}
              boxSize={getHeightInPixels(16)}
            />
            <Flex flexWrap={"wrap"} justify="center" mt={getHeightInPixels(4)}>
              <Text>{t("offer.redirect_1")}</Text>
              <Text mx={1} fontWeight={"semibold"}>
                {offerOnTop?.title}
              </Text>
              <Text>{!isAndroidStore ? t("redirect_2_apple"): t("offer.redirect_2")}</Text>
            </Flex>
            <Flex flexWrap={"wrap"} justify="center" mt={getHeightInPixels(3)}>
              <Text fontWeight={"semibold"}>{t("offer.redirect_info_1")}</Text>
              <Text mx={1}>{t("offer.redirect_info_2")}</Text>
            </Flex>
          </Flex>
          <Flex mt={getHeightInPixels(2)} w="100%">
            <GradientButton
              onClick={() => {
                if (offerOnTop) {
                  dispatch(
                    PersistOfferClickAction(
                      offerOnTop.partnerCampaignId,
                      offerOnTop.packageName,
                      offerOnTop.clickUrl,
                      offerOnTop.isClickForward
                    )
                  );
                }
              }}
              animate
            >
              <Text fontWeight={"semibold"} fontSize={getHeightInPixels(3)}>
                {t("offer.redirect_cta")}
              </Text>
              <Flex
                  bgColor={"white"}
                  borderRadius="full"
                  p={getWidthInPixels(1.5)}
                  pl={getWidthInPixels(2.6)}
                  ml={getWidthInPixels(4)}
                  boxSize={getHeightInPixels(5)}
                  alignItems="center"
                  justify={"center"}
              >
                <Image
                    src={isAndroidStore ? "/gplay_icon.png" : "apple_icon.webp"}
                    objectFit="contain"
                />
              </Flex>
            </GradientButton>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default OfferOnTopModal;
