import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode, useMemo } from "react";
import ScanQRMessage from "src/components/ScanQRMessage";
import { PURPLE } from "src/constants/colors";
import useCustomSizing from "src/hooks/useCustomSizing";
import { getDeviceInformations } from "src/hooks/User";

type Props = {
  children: ReactNode;
};

const Container = ({ children }: Props) => {
  const { getHeightInPixels, getWidthInPixels, isMobile, TOP_NOTCH_HEIGHT } =
    useCustomSizing();

  const isMobileDeviceInfo = useMemo(() => {
    const userAgent = window.navigator.userAgent;
    const deviceInfo = getDeviceInformations(userAgent);
    return deviceInfo.os === "iOS" || deviceInfo.os === "Android";
  }, []);

  const isImprintPageDisplayed = window.location.pathname === '/imprint'

  return (
    <Flex
      w={isMobile ? getWidthInPixels(100) : "100vw"}
      h={isMobile ? getHeightInPixels(100) : "100vh"}
      alignItems="center"
      justify={"center"}
      backgroundColor="#3a007c"
      bgGradient={"linear(to-tr, #3a007c,  #46078e)"}
      pos="relative"
      overflow="hidden"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Flex
        w={getWidthInPixels(100)}
        h={getHeightInPixels(100)}
        flexDir="column"
        background={PURPLE}
        overflow={isMobile ? "-moz-hidden-unscrollable" : "hidden"}
        position={"relative"}
        borderRadius={!isMobile ? getHeightInPixels(6) : 0}
        boxSizing={isMobile ? undefined : "content-box"}
        borderWidth={getWidthInPixels(isMobile ? 0 : 4)}
        borderColor={"#2b2d37"}
      >
        {!isMobile && (
          <Flex
            position="absolute"
            justify={"center"}
            top={0}
            right={0}
            left={0}
            zIndex={99999}
          >
            <Box
              w={getWidthInPixels(isMobile ? 0 : 50)}
              height={getHeightInPixels(TOP_NOTCH_HEIGHT)}
              backgroundColor={"#2b2d37"}
              borderBottomLeftRadius={getWidthInPixels(5)}
              borderBottomRightRadius={getWidthInPixels(5)}
            />
          </Flex>
        )}
        {isMobileDeviceInfo || isImprintPageDisplayed ? (
          children
        ) : (
          <ScanQRMessage />
        )}
      </Flex>
    </Flex>
  );
};

export default Container;
