import React from "react";
import { useDispatch } from "react-redux";
import BaseModal from "src/components/modals/BaseModal";
import StarAnimation from "../../assets/animations/happy_star.json";
import Lottie from "lottie-react";
import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import GradientButton from "src/components/GradientButton";
import { CloseNotificationAction } from "src/redux/Notification/action";

export const RATE_APP_REVIEW_TYPE = "rate_app_review";

const RateAppReviewModal = () => {
  const dispatch = useDispatch();

  const url = "https://play.google.com/store/apps/details?id=pro.cashtree";

  return (
    <BaseModal>
      <Flex
        flexDir={"column"}
        p={4}
        alignItems="center"
        flex={1}
        justifyContent="center"
        bgGradient={`linear(to-tr, #4000ff, #7B00FF, #7B00FF)`}
      >
        <Lottie
          style={{ height: 120 }}
          autoPlay={true}
          animationData={StarAnimation}
        />
        <Flex flex={1} flexDir={"column"} justifyContent="center">
          <Text
            fontSize={24}
            fontWeight="bold"
            color={"white"}
            align="center"
            noOfLines={1}
            mb={2}
            mt={4}
          >
            {t("app_review.headline")}
          </Text>
          <Text
            fontSize={16}
            fontWeight="normal"
            color={"white"}
            align="center"
            noOfLines={3}
            mb={4}
            /* style={styles.descriptionText} */
          >
            {t("app_review.description")}
          </Text>
          <Text
            fontSize={18}
            fontWeight="semibold"
            color={"white"}
            align="center"
            noOfLines={2}
            mb={2}
            /* style={styles.appreciateText} */
          >
            {t("app_review.appreciate")}
          </Text>
        </Flex>
        <Flex flexDir={"column"} w="full">
          <GradientButton
            onClick={() => {
              window.open(url, "_blank");
            }}
          >
            {t("app_review.review")}
          </GradientButton>
          <Text
            mt={2}
            fontSize={16}
            fontWeight="medium"
            color={"whiteAlpha.800"}
            align="center"
            onClick={() => dispatch(CloseNotificationAction())}
            noOfLines={1}

            /* style={styles.cancelText} */
          >
            {t("app_review.not_now")}
          </Text>
        </Flex>
      </Flex>
    </BaseModal>
  );
};

export default RateAppReviewModal;
