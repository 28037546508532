import { combineReducers } from "redux";
import { AppUsage, AppUsageState } from "src/redux/AppUsage/reducer";
import {
  NotificationListener,
  NotificationListenerState,
} from "src/redux/Notification/reducer";
import { Offers, OffersState } from "src/redux/Offers/reducer";
import { Payout, PayoutState } from "src/redux/Payout/reducer";
import {
  PayoutHistory,
  PayoutHistoryState,
} from "src/redux/PayoutHistory/reducer";
import { User, UserState } from "src/redux/User/reducer";
import { UserCreate, UserCreateState } from "src/redux/UserCreate/reducer";

const store = (state: any, action: any) => {
  if (action.type === "reset-app") {
    state = undefined;
  }

  return allReducers(state, action);
};

const allReducers = combineReducers({
  offers: Offers,
  payout: Payout,
  payoutHistory: PayoutHistory,
  notificationListener: NotificationListener,
  usage: AppUsage,
  userCreate: UserCreate,
  user: User,
});

export interface RootReducer {
  offers: OffersState;
  payout: PayoutState;
  payoutHistory: PayoutHistoryState;
  notificationListener: NotificationListenerState;
  usage: AppUsageState;
  user: UserState;
  userCreate: UserCreateState;
}

export default store;
