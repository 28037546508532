import { RequestStatus } from "src/types/Request";

export interface OffersState {
  offers: Offer[];
  fetchOffersRequestState: RequestStatus;
  isVideoPlaying: boolean;
}

export interface Offer {
  partnerCampaignId: number;
  title: string;
  subTitle: string | null;
  iconUrl: string;
  bannerImageUrl: string | null;
  bannerVideoUrl: string | null;
  isCoinsPerMinVisible: boolean;
  requiresUsagePermission: boolean;
  packageName: string;
  clickUrl: string;
  isClickForward: boolean;
  totalPayout: number;
  totalPayoutCurrency: string;
}

export const initialState: OffersState = {
  offers: [],
  isVideoPlaying: false,
  fetchOffersRequestState: {
    isSuccessful: false,
    isFailed: false,
    isLoading: false,
  },
};

export function Offers(state = initialState, action: any) {
  switch (action.type) {
    case "offers-update-offers":
      return {
        ...state,
        offers: action.offers,
      };
    case "offers-update-fetch-offers-request-state":
      return {
        ...state,
        fetchOffersRequestState: action.requestState,
      };
    case "offers-update-video-status":
      return {
        ...state,
        isVideoPlaying: action.isVideoPlaying,
      };
    default:
      return state;
  }
}
