import moment from "moment";
import { PayoutHistoryType } from "src/redux/PayoutHistory/reducer";

export const TransformFetchPayoutHistoryRequestState = (
  apiResponse: FetchPayoutHistoryResponse[]
): PayoutHistoryType[] => {
  let transformed: PayoutHistoryType[] = [];

  apiResponse
    .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
    .forEach((h) => {
      const convertedCreatedAt = moment(h.createdAt).format("MM-DD-YYYY");
      transformed.push({
        coinsSpent: h.coin,
        currency: h.currency,
        imageUrl: h.imageUrl,
        value: h.value,
        status: h.status,
        createdAt: convertedCreatedAt,
        securityCode: h.securityCode ?? undefined,
        redemptionUrl: h.redemptionUrl ?? undefined,
      });
    });

  return transformed;
};

interface FetchPayoutHistoryResponse {
  value: number;
  currency: string;
  coin: number;
  imageUrl: string;
  status: string;
  createdAt: string;
  securityCode?: string;
  redemptionUrl?: string;
}
