const ENDPOINTS = {
  sandbox: "https://app-sandbox.thespotlight.pro",
  prod: "https://app-api.thespotlight.pro",
};
const ENV = "prod";

const API_ENDPOINT = ENDPOINTS[ENV];

export const Post = async (
  endpoint: string,
  body: object | null
): Promise<any> => {
  return await post(API_ENDPOINT + endpoint, body);
};

export const post = async (
  fullUrl: string,
  body: object | null
): Promise<any> => {
  const token = getToken();

  let response = await fetch(fullUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: body ? JSON.stringify(body) : JSON.stringify({}),
  });

  return await response.json().catch((_) => {
    return {};
  });
};

export const Get = async (fullUrl: string): Promise<any> => {
  let response = await fetch(fullUrl, {
    method: "GET",
  });

  return await response.json().catch((_) => {
    return {};
  });
};

const getToken = (): string => {
  const token = localStorage.getItem("token");
  if (!token) {
    return "";
  }

  return token;
};
