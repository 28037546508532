// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

import { connect } from "react-redux";

import { useHandleAppStateChange, useHandleInitApp } from "src/hooks/AppState";

function AppStateListener() {
  useHandleInitApp();
  useHandleAppStateChange();

  return null;
}

export default connect()(AppStateListener);
