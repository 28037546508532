import { useEffect, useState } from "react";

/* import messaging from '@react-native-firebase/messaging'; */

import { useDispatch, useSelector } from "react-redux";

/* import { refreshIsAppUsagePermissionGiven } from '@components/listeners/AppUsage'; */
import {
  FetchActiveCampaigns,
  FetchClickedCampaigns,
  FetchInstalledCampaigns,
} from "src/redux/AppUsage/action";
/* import {
  FetchCoinBoost,
  ShowUsagePermissionRequest,
} from "src/redux/Notification/action"; */
import { FetchOffers } from "src/redux/Offers/action";
import { FetchPayoutOptions } from "src/redux/Payout/action";
import { RootReducer } from "src/redux";
import { FetchWalletAction, UpdateFcmTokenAction } from "src/redux/User/action";
import { setI18nConfig } from "src/utils/translate";

/* const { AppUsageModule } = NativeModules; */

export const useHandleInitApp = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootReducer) => state.user.userId);
  const isUserAnon = useSelector((state: RootReducer) => state.user.userIsAnon);
  const userAnonFetchRequestState = useSelector(
    (state: RootReducer) => state.user.userAnonFetchRequestState
  );
  /* const isUsagePermissionGranted = useSelector(
    (state: RootReducer) => state.user.isUsagePermissionGranted
  );
  const installedCampaigns = useSelector(
    (state: RootReducer) => state.usage.installedCampaigns
  ); */

  const isUserIdentified = userId !== null;

  /* useEffect(() => {
    if (
      !isUserAnon &&
      userAnonFetchRequestState.isSuccessful &&
      !isUsagePermissionGranted &&
      installedCampaigns.length > 0
    ) {
      dispatch(ShowUsagePermissionRequest());
    }
  }, [
    dispatch,
    isUserAnon,
    userAnonFetchRequestState,
    isUsagePermissionGranted,
    installedCampaigns,
  ]); */

  /* useEffect(() => {
    const unsubscribe = messaging().onTokenRefresh(token => {
      dispatch(UpdateFcmTokenAction(token));
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]); */

  /* useEffect(() => {
    if (!isUserIdentified) {
      return;
    }

    (async () => {
      const installedApps = await AppUsageModule.getInstalledApps();

      dispatch(PersistInstalledApps(installedApps));
    })();

    (async () => {
      const historyInstalledApps: String[] =
        await AppUsageModule.getHistoryInstalledApps();

      dispatch(PersistHistoryInstalledApps(historyInstalledApps));
    })();
  }, [dispatch, isUserIdentified]); */

  useEffect(() => {
    setI18nConfig();
    if (!isUserIdentified) {
      return;
    }

    dispatch(FetchPayoutOptions());
    dispatch(FetchWalletAction());

    dispatch(FetchClickedCampaigns());
    /* dispatch(FetchInstalledCampaigns()); */
    dispatch(FetchOffers());
    dispatch(FetchActiveCampaigns());
    /* dispatch(FetchCoinBoost()); */

    if (isUserAnon) {
      return;
    }
  }, [dispatch, isUserIdentified, isUserAnon]);
};

export const useHandleAppStateChange = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootReducer) => state.user.userId);
  const isUserAnon = useSelector((state: RootReducer) => state.user.userIsAnon);
  /* const isUsagePermissionGranted = useSelector(
    (state: RootReducer) => state.user.isUsagePermissionGranted
  ); */
  /* const installedCampaigns = useSelector(
    (state: RootReducer) => state.usage.installedCampaigns
  ); */

  useEffect(() => {
    const _handleFocus = () => {
      if (!userId || isUserAnon) {
        return;
      }

      dispatch(FetchClickedCampaigns());
      dispatch(FetchInstalledCampaigns());
      dispatch(FetchPayoutOptions());
      dispatch(FetchOffers());
      dispatch(FetchWalletAction());
      dispatch(FetchActiveCampaigns());
      /* dispatch(FetchCoinBoost()); */

      /* (async () => {
          const historyInstalledApps: String[] =
            await AppUsageModule.getHistoryInstalledApps();

          dispatch(PersistHistoryInstalledApps(historyInstalledApps));
        })(); */

      /* (async () => {
          const installedApps = await AppUsageModule.getInstalledApps();

          dispatch(PersistInstalledApps(installedApps));
        })(); */

      /* refreshIsAppUsagePermissionGiven(
          dispatch,
          isUsagePermissionGranted,
        ).then((isGranted: boolean) => {
          if (!isGranted && installedCampaigns.length > 0) {
            dispatch(ShowUsagePermissionRequest());
          }
        }); */
    };

    window.addEventListener("focus", _handleFocus);

    return () => window.removeEventListener("focus", _handleFocus);
  }, [dispatch, isUserAnon, userId]);
};
