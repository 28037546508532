import { AppTrackingInfo } from 'src/redux/AppUsage/reducer';

export interface UsageDeviceState {
  isLayingFlat: boolean;
  isMoving: boolean;
}

export const PersistUsageAction = (
  packageName: string,
  partnerCampaignId: number,
  usage: number,
  deviceState: UsageDeviceState,
) => {
  return {
    type: 'usage-persist-usage',
    packageName: packageName,
    partnerCampaignId: partnerCampaignId,
    usage: usage,
    deviceState: deviceState,
  };
};

export const PersistInstallAction = (
  packageName: string,
  partnerCampaignId: number,
) => {
  return {
    type: 'usage-persist-install',
    packageName: packageName,
    partnerCampaignId: partnerCampaignId,
  };
};

export const FetchClickedCampaigns = () => {
  return {
    type: 'usage-fetch-clicked-campaigns',
  };
};

export const FetchActiveCampaigns = () => {
  return {
    type: 'usage-fetch-active-campaigns',
  };
};

export const FetchInstalledCampaigns = () => {
  return {
    type: 'usage-fetch-installed-campaigns',
  };
};

export const UpdateClickedCampaigns = (campaigns: AppTrackingInfo[]) => {
  return {
    type: 'usage-update-clicked-campaigns',
    campaigns: campaigns,
  };
};

export const UpdateActiveCampaigns = (campaigns: AppTrackingInfo[]) => {
  return {
    type: 'usage-update-active-campaigns',
    campaigns: campaigns,
  };
};

export const UpdateInstalledCampaigns = (campaigns: AppTrackingInfo[]) => {
  return {
    type: 'usage-update-installed-campaigns',
    campaigns: campaigns,
  };
};

export const AddToClickedCampaignsAction = (campaign: AppTrackingInfo) => {
  return {
    type: 'usage-add-clicked-campaign',
    campaign: campaign,
  };
};

export const AddToInstalledCampaignsAction = (campaign: AppTrackingInfo) => {
  return {
    type: 'usage-add-installed-campaign',
    campaign: campaign,
  };
};
