import { Offer } from 'src/redux/Offers/reducer';

export const TransformFetchOffersResponse = (
  apiResponse: FetchOffersResponse,
): Offer[] => {
  let transformed: Offer[] = [];

  apiResponse.offers.forEach(o => {
    transformed.push({...o});
  });

  return transformed;
};

interface FetchOffersResponse {
  offers: FetchOffersResponseOffer[];
}

interface FetchOffersResponseOffer {
  partnerCampaignId: number;
  title: string;
  subTitle: string | null;
  iconUrl: string;
  bannerImageUrl: string | null;
  bannerVideoUrl: string | null;
  isCoinsPerMinVisible: boolean;
  coinsPerMin: number;
  requiresUsagePermission: boolean;
  packageName: string;
  clickUrl: string;
  isClickForward: boolean;
  totalPayout: number;
  totalPayoutCurrency: string;
}
