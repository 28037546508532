/* import { InstalledApp, UserWallet } from "@root/_redux/User/reducer"; */

import { UserWallet } from "src/redux/User/reducer";

export const UpdateFcmTokenAction = (fcmToken: string) => {
  return {
    type: "user-update-fcm-token",
    fcmToken: fcmToken,
  };
};

export const UpdateIsNotificationPermissionGrantedAction = (
  isNotificationPermissionGranted: boolean
) => {
  return {
    type: "user-update-notification-permission-granted",
    isNotificationPermissionGranted: isNotificationPermissionGranted,
  };
};

/* export const PersistInstalledApps = (installedApps: InstalledApp[]) => ({
  type: "user-persist-installed-apps",
  installedApps: installedApps,
}); */

/* export const PersistHistoryInstalledApps = (
  historyInstalledApps: String[]
) => ({
  type: "user-persist-history-installed-apps",
  historyInstalledApps: historyInstalledApps,
}); */

export const FetchWalletAction = () => {
  return {
    type: "user-fetch-wallet",
  };
};

export const UpdateWalletAction = (wallet: UserWallet) => {
  return {
    type: "user-update-wallet",
    wallet: wallet,
  };
};
