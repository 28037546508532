import { RequestStatus } from "src/types/Request";

export const RequestSuccessfulUpdate: RequestStatus = {
  isLoading: false,
  isSuccessful: true,
  isFailed: false,
};

export const RequestFailedUpdate: RequestStatus = {
  isLoading: false,
  isSuccessful: false,
  isFailed: true,
};

export const RequestLoadingUpdate: RequestStatus = {
  isLoading: true,
  isSuccessful: false,
  isFailed: false,
};
