export const PRIMARY_COLOR = "#00f900";
export const SECONDARY_COLOR = "#fffb00";
export const PURPLE = "#7B00FF";
export const SECONDARY_PURPLE = "#4000ff";

export const BLUE_GREY = "#2e2e34";

export const GRADIENT_PURPLE_1 = "#420bb0";
export const GRADIENT_PURPLE_2 = "#971dec";
export const GRADIENT_PURPLE_3 = "#aa31ff";

export const GRADIENT_BUTTON_1 = "#fc0fa5";
export const GRADIENT_BUTTON_2 = "#ff4f4f";
export const GRADIENT_BUTTON_3 = "#ff7d44";