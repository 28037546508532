export const TransformFetchTrackedAppsResponse = (
  apiResponse: FetchTrackedAppsResponse,
): FetchTrackedAppsResponseTrackingInfo[] => {
  return apiResponse.trackedApps;
};

interface FetchTrackedAppsResponse {
  trackedApps: FetchTrackedAppsResponseTrackingInfo[];
}

interface FetchTrackedAppsResponseTrackingInfo {
  partnerCampaignId: number;
  packageName: string;
}
