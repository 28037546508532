import { RequestStatus } from "src/types/Request";

export type PayoutHistoryType = {
  value: number;
  currency: string;
  coinsSpent: number;
  status: string;
  imageUrl: string;
  createdAt: string;
  redemptionUrl?: string;
  securityCode?: string;
};

export interface PayoutHistoryState {
  payoutHistory: PayoutHistoryType[];
  fetchPayoutHistoryRequestState: RequestStatus;
  isModalOpen: boolean;
}

export const initialState: PayoutHistoryState = {
  payoutHistory: [],
  isModalOpen: false,
  fetchPayoutHistoryRequestState: {
    isLoading: false,
    isSuccessful: false,
    isFailed: false,
  },
};

export function PayoutHistory(state = initialState, action: any) {
  switch (action.type) {
    case "payout-history-update-payout-history":
      return {
        ...state,
        payoutHistory: action.payoutHistory,
      };
    case "payout-history-update-fetch-payout-history-request-state":
      return {
        ...state,
        fetchPayoutHistoryRequestState: action.requestState,
      };
    case "payout-history-update-payout-history-modal":
      return {
        ...state,
        isModalOpen: action.isBSOpen,
      };
    default:
      return state;
  }
}
