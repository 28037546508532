import { Button, keyframes } from "@chakra-ui/react";
import {
  GRADIENT_BUTTON_1,
  GRADIENT_BUTTON_2,
  GRADIENT_BUTTON_3,
  PURPLE,
} from "../constants/colors";
import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import { hexToRGB } from "src/utils/color";
import useCustomSizing from "src/hooks/useCustomSizing";

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  py?: number;
  animate?: boolean;
  children: ReactNode;
};

const animationKeyframes = keyframes`
  0% { transform: scale(0.95); box-shadow: ${hexToRGB(
    GRADIENT_BUTTON_2,
    0.1
  )} 0px 8px 24px; }
  50% { transform: scale(1.06); box-shadow: ${hexToRGB(
    GRADIENT_BUTTON_2,
    0.6
  )} 0px 8px 24px; }
  75% { transform: scale(0.95); box-shadow: ${hexToRGB(
    GRADIENT_BUTTON_2,
    0.1
  )} 0px 8px 24px; }
  100% { transform: scale(0.95); box-shadow: ${hexToRGB(
    GRADIENT_BUTTON_2,
    0.1
  )} 0px 8px 24px; }
`;

const animation = `${animationKeyframes} 2s linear infinite`;

const GradientButton = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  py = 3.5,
  animate,
  children,
}: Props) => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  return (
    <Button
      colorScheme={"pink"}
      flexDir="row"
      textTransform="uppercase"
      cursor={"pointer"}
      as={motion.div}
      animation={!disabled && animate ? animation : undefined}
      flex={1}
      px={getWidthInPixels(4)}
      py={getHeightInPixels(py)}
      bgGradient={
        disabled
          ? `linear(to-tr, gray.50, gray.100, gray.200)`
          : `linear(to-tr, ${GRADIENT_BUTTON_1}, ${GRADIENT_BUTTON_2}, ${GRADIENT_BUTTON_3})`
      }
      minH={getHeightInPixels(5)}
      isDisabled={disabled}
      isLoading={loading}
      onClick={onClick}
      fontSize={getHeightInPixels(3.3)}
      opacity={disabled ? 0.5 : 1}
      color={disabled ? "gray.600" : "white"}
      borderRadius={getHeightInPixels(3.5)}
      fontWeight={"bold"}
      alignItems="center"
      justifyContent={"center"}
      textShadow={`1px 1px ${hexToRGB(PURPLE, 0.8)}`}
    >
      {children}
    </Button>
  );
};

export default GradientButton;
