import { call, put, takeLatest } from "redux-saga/effects";

import {
  Usage,
  UsageActiveCampaigns,
  UsageClickedCampaigns,
  UsageInstall,
  UsageInstalledCampaigns,
} from "src/constants/endpoints";
import {
  AddToInstalledCampaignsAction,
  UpdateActiveCampaigns,
  UpdateClickedCampaigns,
  UpdateInstalledCampaigns,
} from "src/redux/AppUsage/action";
import { TransformFetchTrackedAppsResponse } from "src/redux/AppUsage/transformer";
import { Post } from "src/utils/api";

export function* usageSaga() {
  yield takeLatest("usage-persist-usage", persistUsage);
  yield takeLatest("usage-persist-install", persistInstall);
  yield takeLatest("usage-fetch-clicked-campaigns", fetchClickedCampaigns);
  yield takeLatest("usage-fetch-active-campaigns", fetchActiveCampaigns);
  yield takeLatest("usage-fetch-installed-campaigns", fetchInstalledCampaigns);
}

function* fetchClickedCampaigns() {
  try {
    // @ts-ignore
    const response = yield call(Post, UsageClickedCampaigns);

    const clickedCampaigns = TransformFetchTrackedAppsResponse(response);

    yield call(
      localStorage.setItem,
      "clickedCampaigns",
      JSON.stringify(clickedCampaigns)
    );

    yield put(UpdateClickedCampaigns(clickedCampaigns));
  } catch (e) {}
}

function* fetchActiveCampaigns() {
  try {
    // @ts-ignore
    const response = yield call(Post, UsageActiveCampaigns);
    yield put(UpdateActiveCampaigns(response));
  } catch (e) {}
}

function* fetchInstalledCampaigns() {
  try {
    // @ts-ignore
    const response = yield call(Post, UsageInstalledCampaigns);

    const installedCampaigns = TransformFetchTrackedAppsResponse(response);

    yield call(
      localStorage.setItem,
      "installedCampaigns",
      JSON.stringify(installedCampaigns)
    );

    yield put(UpdateInstalledCampaigns(installedCampaigns));
  } catch (e) {}
}

function* persistUsage(action: any) {
  try {
    yield call(Post, Usage, {
      packageName: action.packageName,
      partnerCampaignId: action.partnerCampaignId,
      usage: action.usage,
      deviceState: action.deviceState,
    });
  } catch (e) {}
}

function* persistInstall(action: any) {
  try {
    yield call(Post, UsageInstall, {
      packageName: action.packageName,
      partnerCampaignId: action.partnerCampaignId,
    });

    yield put(
      AddToInstalledCampaignsAction({
        partnerCampaignId: action.partnerCampaignId,
        packageName: action.packageName,
      })
    );
  } catch (e) {}
}
