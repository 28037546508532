/* import { I18nManager } from "react-native"; */

import { use } from "i18next";
import { initReactI18next } from "react-i18next";
/* import { findBestAvailableLanguage } from "react-native-localize"; */

type SupportedLanguages = "en" | "de";
type LanguageResources = Record<
  SupportedLanguages,
  Record<string, NodeRequire>
>;

export const setI18nConfig = async () => {
  const resources: LanguageResources = {
    en: require("../translations/en.json"),
    de: require("../translations/de.json"),
  };

  /* const fallback = { languageTag: 'en', isRTL: false };
	const supportedLanguages = Object.keys(resources); */
  /* const { languageTag, isRTL } =
    findBestAvailableLanguage(supportedLanguages) || fallback;
 */
  await use(initReactI18next).init({
    compatibilityJSON: "v3",
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  /* I18nManager.forceRTL(isRTL); */
};
