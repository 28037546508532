import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MyGamesCard from "src/components/MyGamesCard";
import useCustomSizing from "src/hooks/useCustomSizing";
import { RootReducer } from "src/redux";

const MyGames = () => {
  const { getHeightInPixels } = useCustomSizing();
  const activeCampaigns = useSelector(
    (state: RootReducer) => state.usage.activeCampaigns
  );

  const showCoinsPage = useSelector(
    (state: RootReducer) => state.user.showCoinsPage
  );

  return (
    <Flex
      flex={1}
      flexDir="column"
      backgroundColor="white"
      overflow={"hidden"}
      position="relative"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {/* <ReferralFAB /> */}
      {activeCampaigns.length < 1 ? (
        <Flex flexDir={"column"} p={getHeightInPixels(4)}>
          {showCoinsPage ? (
            <Text
              textAlign={"center"}
              color={"#606060"}
              fontWeight="medium"
              fontSize={getHeightInPixels(1.75)}
            >
              {t("myapps.no_installed_app")}
            </Text>
          ) : (
            <Text
              textAlign={"center"}
              color={"#606060"}
              fontWeight="medium"
              fontSize={getHeightInPixels(1.75)}
            >
              {t("myapps.no_active_game")}
            </Text>
          )}
        </Flex>
      ) : (
        <SimpleGrid
          alignContent={"flex-start"}
          columns={1}
          flex={1}
          gap={getHeightInPixels(4)}
          padding={getHeightInPixels(2)}
          pb={getHeightInPixels(16)}
          overflow={"scroll"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {activeCampaigns.map((cmpgn) => (
            <MyGamesCard key={cmpgn.packageName} campaign={cmpgn} />
          ))}
        </SimpleGrid>
      )}
    </Flex>
  );
};

export default MyGames;
