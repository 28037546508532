import { call, put, takeLatest } from "redux-saga/effects";

import { PayoutHistory } from "src/constants/endpoints";
import {
  RequestFailedUpdate,
  RequestLoadingUpdate,
  RequestSuccessfulUpdate,
} from "src/constants/RequestStatus";
import {
  UpdateFetchPayoutHistoryRequestState,
  UpdatePayoutHistory,
} from "src/redux/PayoutHistory/action";
import { TransformFetchPayoutHistoryRequestState } from "src/redux/PayoutHistory/transformer";
import { Post } from "src/utils/api";

export function* payoutHistorySaga() {
  yield takeLatest("payout-history-fetch", fetchPayoutHistory);
}

function* fetchPayoutHistory() {
  try {
    yield put(UpdateFetchPayoutHistoryRequestState(RequestLoadingUpdate));
    //@ts-ignore
    const response = yield call(Post, PayoutHistory);

    yield put(
      UpdatePayoutHistory(TransformFetchPayoutHistoryRequestState(response))
    );

    yield put(UpdateFetchPayoutHistoryRequestState(RequestSuccessfulUpdate));
  } catch (e) {
    yield put(UpdateFetchPayoutHistoryRequestState(RequestFailedUpdate));
  }
}
