import React from "react";
import {
  Flex,
  Heading,
  Image,
  Progress,
  ProgressLabel,
} from "@chakra-ui/react";
import { t } from "i18next";
import { PayoutOption } from "src/redux/Payout/reducer";
import { useDispatch } from "react-redux";
import { ShowPayoutRequest } from "src/redux/Notification/action";
import { hexToRGB } from "src/utils/color";
import { PURPLE } from "src/constants/colors";
import useCustomSizing from "src/hooks/useCustomSizing";

type PayoutCardProps = {
  opt: PayoutOption;
  userCoins: number;
};

const PayoutOptionCard = ({ opt, userCoins }: PayoutCardProps) => {
  const { getHeightInPixels } = useCustomSizing();
  const dispatch = useDispatch();

  const isPayPal = opt.category === 'paypal';

  return (
    <Flex
      w="100%"
      key={`${opt.category} - ${opt.coins}`}
      backgroundColor="white"
      boxShadow={"md"}
      px={2}
      py={3}
      borderRadius={8}
      flexDir="column"
      alignItems={"center"}
      justify="center"
      cursor="pointer"
      onClick={() => {
        if (userCoins < opt.coins) {
          alert(t("payout.not_enough_coins"));
        } else {
          dispatch(ShowPayoutRequest(opt));
        }
      }}
    >
      <Heading fontSize={getHeightInPixels(2.5)}>
        {opt.currency}
        {opt.value.toFixed(2)}
      </Heading>
      <Image
        src={opt.imageUrl}
        m={isPayPal? 4 : 0}
        boxSize={isPayPal ? getHeightInPixels(8) : getHeightInPixels(12)}
        objectFit="contain"
      />
      <Progress
        value={(userCoins / opt.coins) * 100}
        min={0}
        max={100}
        w="100%"
        h={getHeightInPixels(4)}
        borderRadius={getHeightInPixels(5)}
        colorScheme={"pink"}
        bgColor='#f2dcfc'
      >
        <ProgressLabel
          fontSize={getHeightInPixels(2)}
          textShadow={`1px 1px ${hexToRGB(PURPLE, 0.4)}`}
          color={"white"}
        >
          {opt.coins} {t("coins")}
        </ProgressLabel>
      </Progress>
    </Flex>
  );
};

export default PayoutOptionCard;
