import React, { useCallback } from "react";

import { t } from "i18next";

// @ts-ignore
import { Trans } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { RootReducer } from "src/redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import BaseModal from "src/components/modals/BaseModal";
import GradientButton from "src/components/GradientButton";
import Lottie from "lottie-react";
import BlobAnimation from "../../assets/animations/blob.json";
import useCustomSizing from "src/hooks/useCustomSizing";

export const REFERRAL_OFFER_TYPE = "referral_offer";

const ReferralOffer = () => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const userUuid = useSelector((state: RootReducer) => state.user.userUuid);

  /* const handleShare = useCallback(() => {
    play(SoundList.DoublePop);
    Share.share({
      title: i18next.t("referral.share_text") + buildShareUrl(userUuid),
      url: buildShareUrl(userUuid),
    });
  }, [userUuid]); */

  return (
    <BaseModal>
      <Flex
        flexDir={"column"}
        p={getHeightInPixels(4)}
        alignItems="center"
        flex={1}
        justifyContent="center"
        bgGradient={`linear(to-tr, #4000ff, #7B00FF, #7B00FF)`}
      >
        <Text
          color={"white"}
          fontSize={24}
          fontWeight="bold"
          textAlign={"center"}
          noOfLines={2}
        >
          <Trans
            i18nKey="referral.double"
            components={{
              component: (
                <Text
                  color={"white"}
                  fontSize={24}
                  fontWeight="black"
                  textAlign={"center"}
                  noOfLines={2}
                />
              ),
            }}
          />
        </Text>
        <Flex pos="relative" my={getHeightInPixels(2)}>
          <Image height={getHeightInPixels(24)} src="/images/icon_gift.png" />
          <Box pos={"absolute"} zIndex={100} bottom={0}>
            <Lottie
              animationData={BlobAnimation}
              style={{
                width: getWidthInPixels(24),
              }}
              loop
            />
          </Box>
        </Flex>
        <Text
          textAlign={"center"}
          color={"white"}
          fontSize={16}
          fontWeight="normal"
          noOfLines={3}
        >
          <Trans
            i18nKey="referral.invite_text"
            components={{
              component: (
                <Text
                  textAlign={"center"}
                  color={"white"}
                  fontSize={18}
                  fontWeight="bold"
                />
              ),
            }}
          />
        </Text>
        <Flex mt={6} w="100%">
          <GradientButton animate>
            <Text
              fontWeight={"semibold"}
              fontSize={getHeightInPixels(3)}
              textTransform="uppercase"
            >
              {t("referral.cta")}
            </Text>
          </GradientButton>
        </Flex>
      </Flex>
    </BaseModal>
  );
};

const buildShareUrl = (referrerUserUuid: string): string => {
  return `https://play.sng.link/Ds1te/0760?psid=${referrerUserUuid}`;
};

export default connect()(ReferralOffer);
