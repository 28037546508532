import { PayoutHistoryType } from "src/redux/PayoutHistory/reducer";
import { RequestStatus } from "src/types/Request";


export const UpdateFetchPayoutHistoryRequestState = (
  requestState: RequestStatus,
) => {
  return {
    type: 'payout-history-update-fetch-payout-history-request-state',
    requestState: requestState,
  };
};

export const FetchPayoutHistory = () => {
  return {
    type: 'payout-history-fetch',
  };
};

export const UpdatePayoutHistory = (payoutHistory: PayoutHistoryType[]) => {
  return {
    type: 'payout-history-update-payout-history',
    payoutHistory: payoutHistory,
  };
};

export const UpdatePayoutHistoryModal = (value: boolean) => {
  return {
    type: 'payout-history-update-payout-history-modal',
    isModalOpen: value,
  };
};
