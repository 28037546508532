import { NOTIFICATION_PERMISSION_REQUEST_TYPE } from "src/components/modals/NotificationPermissionModal";
import { OFFER_ON_TOP_TYPE } from "src/components/modals/OfferOnTopModal";
import { PAYOUT_REQUEST_TYPE } from "src/components/modals/PayoutRequestModal";
import { RATE_APP_REVIEW_TYPE } from "src/components/modals/RateAppReviewModal";
import { REFERRAL_OFFER_TYPE } from "src/components/modals/ReferralModal";
import { WELCOME_REWARD_TYPE } from "src/components/modals/WelcomeGiftModal";
import { CoinBoost } from "src/redux/Notification/reducer";
import { Offer } from "src/redux/Offers/reducer";
import { PayoutOption } from "src/redux/Payout/reducer";
import { RequestStatus } from "src/types/Request";

export const ClearAllNotificationsAction = () => {
  return {
    type: "notification-terminal-clear",
  };
};

export const ShowReferralOffer = () => {
  return {
    type: "notification-terminal-show-type",
    notificationType: REFERRAL_OFFER_TYPE,
  };
};

export const PersistBoostFinished = () => {
  return {
    type: "notification-persist-boost-finished",
  };
};

export const ShowWelcomeReward = () => {
  return {
    type: "notification-terminal-show-type",
    notificationType: WELCOME_REWARD_TYPE,
  };
};

export const FetchCoinBoost = (displayInfoScreen?: boolean) => {
  return {
    type: "notification-fetch-coin-boost",
    displayInfoScreen: displayInfoScreen,
  };
};

export const UpdateCoinBoost = (coinBoost: CoinBoost | null) => {
  return {
    type: "notification-update-coin-boost",
    coinBoost: coinBoost,
  };
};

export const UpdateFetchCoinBoostRequestState = (
  requestState: RequestStatus
) => {
  return {
    type: "notification-update-fetch-coin-boost-request-state",
    requestState: requestState,
  };
};

export const ShowPayoutRequest = (option: PayoutOption) => {
  return {
    type: "notification-terminal-show-type",
    notificationType: PAYOUT_REQUEST_TYPE,
    params: {
      option: option,
    },
  };
};

/* export const ShowRateAppScreenAction = () => {
  return {
    type: "notification-terminal-show-type",
    notificationType: RATE_APP_TYPE,
  };
}; */

export const ShowRateAppReviewScreenAction = () => {
  return {
    type: "notification-terminal-show-type",
    notificationType: RATE_APP_REVIEW_TYPE,
  };
};

export const CloseNotificationAction = () => {
  return {
    type: "notification-terminal-show-type",
    notificationType: null,
    params: null,
  };
};

export const OverWriteNotificationAction = (action: any) => {
  return {
    ...action,
    shouldOverwrite: true,
  };
};

export const ShowOfferOnTopAction = (offer: Offer) => {
  return {
    type: "notification-terminal-show-type",
    notificationType: OFFER_ON_TOP_TYPE,
    params: offer,
  };
};

export const ShowNotificationPermissionRequest = ({
  callback = () => {},
}: {
  callback?: () => void;
}) => {
  return {
    type: "notification-terminal-show-type",
    notificationType: NOTIFICATION_PERMISSION_REQUEST_TYPE,
    params: { callback },
  };
};
