import { call, takeLatest } from "redux-saga/effects";

import { Reward } from "src/constants/endpoints";
import { Post } from "src/utils/api";

export function* rewardSaga() {
  yield takeLatest("reward-persist-reward", persistReward);
}

function* persistReward(action: any) {
  try {
    // @ts-ignore
    yield call(Post, Reward, { rewardType: action.rewardType });
  } catch (e) {}
}
