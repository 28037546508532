import { PayoutOptionCategory } from "src/redux/Payout/reducer";
import { RequestStatus } from "src/types/Request";

export const UpdateFetchPayoutOptionsRequestState = (
  requestState: RequestStatus
) => {
  return {
    type: "payout-update-fetch-payout-options-request-state",
    requestState: requestState,
  };
};

export const FetchPayoutOptions = () => {
  return {
    type: "payout-options-fetch",
  };
};

export const UpdatePayoutOptions = (
  payoutOptionCategories: PayoutOptionCategory[]
) => {
  return {
    type: "payout-update-payout-options",
    payoutOptionCategories: payoutOptionCategories,
  };
};

export const RequestPayoutAction = (
  value: number,
  currency: string,
  coins: number,
  category: string,
  mail: string
) => {
  return {
    type: "payout-persist-payout-request",
    value: value,
    currency: currency,
    coins: coins,
    category: category,
    mail: mail,
  };
};
