export const innerHeight = window.innerHeight;
export const innerWidth = window.innerWidth;
export const isMobile = innerWidth < 769;
const ratio = 7.5 / 16;

export const phoneHeight = innerHeight * 0.8;
export const phoneWidth = phoneHeight * ratio;

const oneHeightUnit = (isMobile ? innerHeight : phoneHeight) / 100;
const oneWidthUnit = (isMobile ? innerWidth : phoneWidth) / 100;

export const getHeightInPixels = (h: number): string =>
  h * oneHeightUnit + "px";

export const getWidthInPixels = (w: number): string =>
  (w * oneWidthUnit).toString() + "px";
