import { Box, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import ScanQRMessage from "src/components/ScanQRMessage";
import useCustomSizing from "src/hooks/useCustomSizing";
import { getDeviceInformations } from "src/hooks/User";
import {
  GRADIENT_PURPLE_1,
  GRADIENT_PURPLE_2,
  GRADIENT_PURPLE_3,
} from "../constants/colors";

const GradientContainer = ({ children }: { children: ReactNode }) => {
  const { getHeightInPixels, getWidthInPixels, TOP_NOTCH_HEIGHT, isMobile } =
    useCustomSizing();

  const deviceInfo = getDeviceInformations(window.navigator.userAgent);
  const isMobileDeviceInfo =
    deviceInfo.os === "iOS" || deviceInfo.os === "Android";

  return (
    <Flex
      w={isMobile ? getWidthInPixels(100) : "100vw"}
      h={isMobile ? getHeightInPixels(100) : "100vh"}
      alignItems="center"
      justify={"center"}
      backgroundColor="#3a007c"
      bgGradient={"linear(to-tr, #3a007c,  #46078e)"}
      overflow="hidden"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Flex
        w={getWidthInPixels(100)}
        h={getHeightInPixels(100)}
        flexDir="column"
        overflow={isMobile ? "-moz-hidden-unscrollable" : "hidden"}
        position={"relative"}
        borderRadius={!isMobile ? getHeightInPixels(6) : 0}
        boxSizing={isMobile ? undefined : "content-box"}
        borderWidth={getWidthInPixels(isMobile ? 0 : 4)}
        borderColor={"#2b2d37"}
        bgGradient={`linear(to-tr, ${GRADIENT_PURPLE_1}, ${GRADIENT_PURPLE_2}, ${GRADIENT_PURPLE_3})`}
      >
        {!isMobile && (
          <Flex
            position="absolute"
            justify={"center"}
            top={0}
            right={0}
            left={0}
            zIndex={99999}
          >
            <Box
              w={getWidthInPixels(isMobile ? 0 : 50)}
              height={getHeightInPixels(TOP_NOTCH_HEIGHT)}
              backgroundColor={"#2b2d37"}
              borderBottomLeftRadius={getWidthInPixels(5)}
              borderBottomRightRadius={getWidthInPixels(5)}
            />
          </Flex>
        )}
        {isMobileDeviceInfo ? children : <ScanQRMessage />}
      </Flex>
    </Flex>
  );
};

export default GradientContainer;
