import { Button, Image } from "@chakra-ui/react";
import React from "react";
import { PURPLE } from "src/constants/colors";
import useCustomSizing from "src/hooks/useCustomSizing";

const PayoutHistory = ({ onClick }: { onClick: () => void }) => {
  const { getHeightInPixels } = useCustomSizing();
  return (
    <Button
      position={"absolute"}
      zIndex={100}
      bottom={getHeightInPixels(2)}
      backgroundColor={PURPLE}
      boxSize={getHeightInPixels(8)}
      borderRadius={"full"}
      right={"5%"}
      onClick={onClick}
      colorScheme="purple"
    >
      <Image
        height={getHeightInPixels(3.5)}
        position={"absolute"}
        src={"list.png"}
      />
    </Button>
  );
};

export default PayoutHistory;
