import { Flex, Image, Text, Icon, Box } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import {PRIMARY_COLOR, PURPLE} from "src/constants/colors";
import useCustomSizing from "src/hooks/useCustomSizing";
import { ActiveCampaignInfo } from "src/redux/AppUsage/reducer";
import { hexToRGB } from "src/utils/color";
import CoinLottieView from "src/views/CoinLottie";

type MyGamesCardProps = {
  campaign: ActiveCampaignInfo;
};

const MyGamesCard = ({ campaign }: MyGamesCardProps) => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  let { iconUrl, title, earnedCoins, packageName, challenges } = campaign;

  return (
    <Flex
      flexDir={"column"}
      backgroundColor="white"
      boxShadow={"lg"}
      borderRadius={getHeightInPixels(2)}
    >
      <Flex
        width="100%"
        boxShadow={"lg"}
        borderTopLeftRadius={getHeightInPixels(2)}
        borderTopRightRadius={getHeightInPixels(2)}
        height={getHeightInPixels(12)}
        position="relative"
      >
        <Image
          src={iconUrl}
          height={getHeightInPixels(12)}
          width={"full"}
          objectFit="cover"
          borderTopLeftRadius={getHeightInPixels(2)}
          borderTopRightRadius={getHeightInPixels(2)}
          position="absolute"
        />
        <Box
          height={getHeightInPixels(12)}
          width={"full"}
          objectFit="cover"
          borderTopLeftRadius={getHeightInPixels(2)}
          borderTopRightRadius={getHeightInPixels(2)}
          position="absolute"
          bgGradient={`linear(to-r, ${hexToRGB("#000000", 0.8)}, ${hexToRGB(
            "#000000",
            0.1
          )})`}
        />
        <Image
            src={iconUrl}
            height={getHeightInPixels(10)}
            width={getHeightInPixels(10)}
            ml={getHeightInPixels(1)}
            alignSelf={'center'}
            borderRadius={20}
            objectFit="cover"
            position="absolute"
        />
        <Flex
          zIndex={999}
          flex={1}
          px={getWidthInPixels(2)}
          py={getHeightInPixels(2)}
          alignItems="center"
        >
          <Flex
            justifyContent={"space-evenly"}
            ml={getWidthInPixels(23)}
            flexDir={"column"}
          >
            <Text
              fontSize={getHeightInPixels(2.25)}
              fontWeight="black"
              color={"white"}
              noOfLines={1}
            >
              {title}
            </Text>
            <Flex>
              <Text

                fontSize={getHeightInPixels(1.75)}
                color="white"
              >
                {t("myapps.total_earned")}:
              </Text>
              <Flex>
                <Text fontWeight={'bold'} ml={2} fontSize={getHeightInPixels(1.75)} color="white">
                  {earnedCoins}
                </Text>
                <CoinLottieView
                  styles={{ width: getWidthInPixels(4), marginLeft: 4 }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          bgGradient={"linear(to-r, #7B00FF, #4000ff)"}
          flexDir="column"
          borderTopRightRadius={getHeightInPixels(2)}
          justify="center"
          align={"center"}
          px={getWidthInPixels(5)}
          zIndex={999}
          onClick={() => {
            if (campaign.packageName.includes(".")) {
              window.open(
                  `https://play.google.com/store/apps/details?id=${packageName}`,
                  "_blank"
              )
            } else {
              window.open(
                  `itms-apps://itunes.apple.com/app/${packageName}`,
                  "_blank"
              )
            }
          }}
        >
          <Icon
            as={AiFillPlayCircle}
            color={"white"}
            boxSize={getHeightInPixels(5)}
          />
          <Text
            fontSize={getHeightInPixels(2)}
            mt={getHeightInPixels(0.75)}
            fontWeight="black"
            color="white"
          >
            PLAY
          </Text>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        px={getWidthInPixels(2)}
        py={getHeightInPixels(2)}
        alignItems="center"
        flexDir={"column"}
      >
        <Flex w={"full"}>
          <Text
            fontWeight={"bold"}
            textDecor=""
            fontSize={getHeightInPixels(2.3)}
            color="gray.700"
            textAlign={"center"}
            width="full"
          >
            {t("myapps.bonus_challenges")}
          </Text>
        </Flex>
        <Flex w={"full"}>
        </Flex>
        <Flex flex={1} mt={getHeightInPixels(2)} flexDir="column" w={"100%"}>
          {challenges.map((chlng) => (
            <Flex
              key={`challenges - ${packageName} - ${chlng.title}`}
              width={"100%"}
              borderRadius={getHeightInPixels(1)}
              justify={"space-between"}
              alignItems="center"
              mb={getHeightInPixels(1)}
            >
              <Flex
                alignItems="center"
                borderRadius={getHeightInPixels(5)}
                height={getHeightInPixels(3.6)}
                bgColor={"#B9B4F8"}
                w="75%"
                position={"relative"}
              >
                <Flex
                  borderRadius={getHeightInPixels(5)}
                  height={getHeightInPixels(4)}
                  bgColor={""}
                  alignSelf="flex-start"
                  transition={"all 1s ease"}
                  w={`${chlng.progress}%`}
                />
                <Flex
                  pos={"absolute"}
                  width="full"
                  alignItems="center"
                  justifyContent={"center"}
                  zIndex={999}
                  height={getHeightInPixels(3.6)}
                >
                  <Text
                    fontSize={getHeightInPixels(1.9)}
                    fontWeight={"bold"}
                    color={chlng.progress === 1 ? PURPLE : "white"}
                    textDecor={chlng.progress === 1 ? "line-through" : "none"}
                    textAlign={"center"}
                    textShadow="0.2px 0.2px black"
                    mt={0.4}
                    pt={0.4}
                    noOfLines={1}
                    flex={1}
                  >
                    {chlng.title}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                borderRadius={getWidthInPixels(4)}
                bgGradient={"linear(to-r, #7B00FF, #4000ff)"}
                ml={getWidthInPixels(4)}
                w={getWidthInPixels(27)}
                h={getHeightInPixels(6)}
                alignItems="center"
                justify={"center"}
                textAlign="center"
                flexDir={"row"}
              >
                {!chlng.rewardAmount ? (
                  <Flex
                    flexDir={"column"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      fontSize={getHeightInPixels(1.5)}
                      textShadow="0.1px 0.1px red"
                      fontWeight="bold"
                      color="white"
                    >
                      Reward
                    </Text>
                    <Flex
                      mt={getHeightInPixels(0.15)}
                      ml={2}
                      overflow="hidden"
                      alignItems={"center"}
                    >
                      <Text
                        fontSize={getHeightInPixels(2.2)}
                        textShadow="0.2px 0.2px red"
                        fontWeight="bold"
                        color="white"
                      >
                        {`+${chlng.rewardCoins}`}
                      </Text>
                      <CoinLottieView
                        styles={{ width: getWidthInPixels(4), marginLeft: 4 }}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex flexDir={"column"} alignItems={"center"}>
                    <Text
                      fontSize={getHeightInPixels(1.5)}
                      textShadow="0.2px 0.2px red"
                      fontWeight="bold"
                      color="white"
                    >
                      FREE Cash
                    </Text>
                    <Text
                      mt={getHeightInPixels(0.15)}
                      fontSize={getHeightInPixels(2)}
                      textShadow="0.2px 0.2px red"
                      fontWeight="bold"
                      color="white"
                    >
                      {chlng.rewardCurrency}
                      {chlng.rewardAmount.toFixed(2)}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MyGamesCard;
