import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import Lottie from "lottie-react";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import GradientButton from "src/components/GradientButton";
import BaseModal from "src/components/modals/BaseModal";
import useCustomSizing from "src/hooks/useCustomSizing";
import { CloseNotificationAction } from "src/redux/Notification/action";
import { PersistReward } from "src/redux/Reward/action";
import ChestAnimation from "../../assets/animations/big_chest.json";
import {FetchWalletAction} from "../../redux/User/action";

export const WELCOME_REWARD_TYPE = "welcome_reward";

const WelcomeGiftModal = () => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const dispatch = useDispatch();
  const persistReward = useCallback(() => {
    dispatch(PersistReward("welcome"));
    dispatch(CloseNotificationAction());
  }, [dispatch]);

  return (
    <BaseModal closeAction={() => {
      persistReward();
      setTimeout(() => {
        dispatch(FetchWalletAction())
      }, 1500)
    }}>
      <Flex
        flexDir={"column"}
        p={getHeightInPixels(4)}
        pt={0}
        alignItems="center"
        flex={1}
        justifyContent="center"
        bgGradient={`linear(to-tr, #4000ff, #7B00FF, #7B00FF)`}
      >
        <Flex pos="relative" marginTop={"-24px"}>
          <Lottie
            animationData={ChestAnimation}
            style={{ width: 260, height: 260, marginTop: -40 }}
            loop
          />
        </Flex>
        <Flex flex={1} flexDir="column" justifyContent={"center"}>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={getHeightInPixels(4)}
            mt={4}
            fontWeight="black"
            noOfLines={1}
          >
            {t("welcome_reward.reward_coins", {
              coins: 3600,
            })}
          </Text>
          <Text
            textAlign={"center"}
            color={"white"}
            fontSize={getHeightInPixels(3)}
            fontWeight="medium"
            noOfLines={1}
          >
            {t("welcome_reward.welcome_gift")}
          </Text>
        </Flex>

        <Flex mt={6} w="100%">
          <GradientButton onClick={() => {
            persistReward();
            setTimeout(() => {
              dispatch(FetchWalletAction())
            }, 1500)
          }} animate>
            <Text
              fontWeight={"semibold"}
              fontSize={24}
              textTransform="uppercase"
            >
              {t("welcome_reward.cta")}
            </Text>
          </GradientButton>
        </Flex>
      </Flex>
    </BaseModal>
  );
};

export default WelcomeGiftModal;
