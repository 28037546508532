import { Flex, Heading, Icon } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { HiStar } from "react-icons/hi";
import { AiFillPlayCircle, AiOutlineDollar } from "react-icons/ai";
import { PURPLE } from "src/constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { IconType } from "react-icons/lib";
import useCustomSizing from "src/hooks/useCustomSizing";

type Tab = {
  id: string;
  icon: IconType;
  title: string;
  to: string;
};

const TABS: Tab[] = [
  {
    id: "offer-tab",
    icon: HiStar,
    title: "New",
    to: "/",
  },
  {
    id: "my-games-tab",
    icon: AiFillPlayCircle,
    title: "My Games",
    to: "/my-games",
  },
  {
    id: "payout-tab",
    icon: AiOutlineDollar,
    title: "Payout",
    to: "/payout",
  },
];

const BottomBar = () => {
  const {
    getHeightInPixels,
    getWidthInPixels,
    BOTTOM_NOTCH_HEIGHT,
    BOTTOM_BAR_HEIGHT,
  } = useCustomSizing();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState<string>(
    TABS.find((tab) => tab.to === pathname)?.id ?? ""
  );

  const onTabPress = useCallback(
    (id: string, to: string) => {
      navigate(to);
      setCurrentTab(id);
    },
    [navigate]
  );

  const Tabs = useMemo(() => {
    return (
      <>
        {TABS.map((_tab) => (
          <Flex
            key={_tab.id}
            onClick={() => onTabPress(_tab.id, _tab.to)}
            flexDir="column"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Icon
              as={_tab.icon}
              bgColor="transparent"
              w={getWidthInPixels(12)}
              h={getHeightInPixels(4.3)}
              mb={getHeightInPixels(0.8)}
              color={currentTab === _tab.id ? 'white' : '#a5a0d7'}
            />
            <Heading
              fontSize={getHeightInPixels(1.6)}
              color={currentTab === _tab.id ? 'white' : "#a5a0d7"}
              textAlign="center"
              fontWeight={currentTab === _tab.id ? "semibold" : "normal"}
            >
              {_tab.title}
            </Heading>
          </Flex>
        ))}
      </>
    );
  }, [currentTab, getHeightInPixels, getWidthInPixels, onTabPress]);
  return (
    <Flex
      alignItems={"center"}
      justifyContent="space-around"
      w={getWidthInPixels(100)}
      backgroundColor={'#751aff'}
      h={getHeightInPixels(BOTTOM_BAR_HEIGHT)}
      zIndex={999}
      bottom={0}
      pb={getHeightInPixels(BOTTOM_NOTCH_HEIGHT)}
    >
      {Tabs}
    </Flex>
  );
};

export default BottomBar;
