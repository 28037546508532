import { call, put, takeEvery } from "redux-saga/effects";
import { UserUpdate } from "src/constants/endpoints";
import {
  RequestFailedUpdate,
  RequestLoadingUpdate,
  RequestSuccessfulUpdate,
} from "src/constants/RequestStatus";
import { ShowWelcomeReward } from "src/redux/Notification/action";
import { UpdateUserInfoRequestStateAction } from "src/redux/UserCreate/action";
import { Post } from "src/utils/api";

export function* userCreateSaga() {
  yield takeEvery("user-create-update-user-info", updateUserInfo);
}

function* updateUserInfo(action: any) {
  try {
    yield put(UpdateUserInfoRequestStateAction(RequestLoadingUpdate));

    // we send the advertiserId again to get IDFA of users that consented
    /* const advertiserId = yield call(GatherAdvertiserId); */

    yield call(Post, UserUpdate, {
      ...action.userInfo,
    });
    yield put(UpdateUserInfoRequestStateAction(RequestSuccessfulUpdate));
    yield sleep();
    yield put(ShowWelcomeReward());
  } catch (e) {
    yield put(UpdateUserInfoRequestStateAction(RequestFailedUpdate));
  }
}

function sleep() {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 500);
  });
}
