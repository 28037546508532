import { Flex, Heading, Image, Text, Icon } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";
import GradientButton from "src/components/GradientButton";
import { Offer } from "src/redux/Offers/reducer";
import { PayoutOption } from "src/redux/Payout/reducer";
import { UserWallet } from "src/redux/User/reducer";
import ReactPlayer from "react-player";
import { AiFillPlayCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "src/redux";
import { ShowNotificationPermissionRequest } from "src/redux/Notification/action";
import useCustomSizing from "src/hooks/useCustomSizing";
import { getDeviceInformations } from "src/hooks/User";
import { isSupported } from "firebase/messaging";
import {GetCurrencySymbolByCode} from "../utils/currency";

type OfferCardProps = {
  offer: Offer;
  index: number;
  wallet: UserWallet | null;
  nextAvailablePayoutOption: PayoutOption | null;
  onPress: () => void;
};

const OfferCard = ({
  offer,
  index,
  wallet,
  nextAvailablePayoutOption,
  onPress,
}: OfferCardProps) => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const dispatch = useDispatch();
  const { isNotificationPermissionGranted } = useSelector(
    (state: RootReducer) => state.user
  );
  const [isPlaying, setPlaying] = useState<boolean>(false);

  const deviceInfo = getDeviceInformations(navigator.userAgent);
  const isAndroidStore = deviceInfo.os === "Android";

  /* const bannerUrl = offer.bannerImageUrl || ""; */
  const videoExists = offer.bannerVideoUrl !== null;
  /* const showOfferBadge = index === 0; */

  /* const missingCoins = nextAvailablePayoutOption?.coins - wallet?.coins; */

  const minutesNeeded = index*2 + 10; //TODO replace with proper logic

  const handleOfferCardClick = async () => {
    const notificationSupported = await isSupported();
    if (!notificationSupported) {
      //notifications not supported
      onPress();
      return;
    }

    onPress();
    /* if (isNotificationPermissionGranted) {
      return;
    }

    //TODO logic has to be that if the user has declined permission once or if firebase fails we call "onPress"
    dispatch(ShowNotificationPermissionRequest({ callback: onPress })); */
  };

  if (!nextAvailablePayoutOption || !wallet) {
    return null;
  }

  return (
    <Flex w={"100%"} boxShadow={"md"} borderRadius="lg" flexDir={"column"}>
      {videoExists ? (
        <Flex onClick={() => setPlaying((prev) => !prev)} pos="relative">
          {!isPlaying ? (
            <Flex pos="relative" width={"100%"} height="100%">
              <Flex
                position="absolute"
                bgColor={"blackAlpha.100"}
                left={0}
                right={0}
                top={0}
                bottom={0}
                alignItems="center"
                justify={"center"}
                borderTopLeftRadius={"lg"}
                borderTopRightRadius="lg"
                height={getHeightInPixels(20)}
              >
                <Icon
                  as={AiFillPlayCircle}
                  color={"white"}
                  boxSize={getHeightInPixels(10)}
                />
              </Flex>
              <Image
                borderTopLeftRadius={"lg"}
                borderTopRightRadius="lg"
                objectFit={"cover"}
                src={offer.bannerImageUrl ?? ""}
                width={"100%"}
                height={getHeightInPixels(20)}
                onClick={() => setPlaying((prev) => !prev)}
              />
            </Flex>
          ) : (
            <ReactPlayer
              width="100%"
              height={getHeightInPixels(20)}
              stopOnUnmount
              playing={isPlaying}
              url={offer.bannerVideoUrl ?? ""}
            />
          )}
        </Flex>
      ) : (
        <Image
          borderTopLeftRadius={"lg"}
          borderTopRightRadius="lg"
          objectFit={"cover"}
          src={offer.bannerImageUrl ?? ""}
          width="100%"
          height={getHeightInPixels(20)}
          onClick={handleOfferCardClick}
        />
      )}
      <Flex
        w={"100%"}
        py={getHeightInPixels(0.75)}
        backgroundColor={getShimmerColor(index)}
        justify={"center"}
        alignItems="center"
        flexDir="row"
        onClick={handleOfferCardClick}
      >
        <Heading
          fontSize={getHeightInPixels(2)}
          color="white"
          mr={getWidthInPixels(1)}
        >
          {t("offer.play_duration1")}
        </Heading>
        <Heading
          fontSize={getHeightInPixels(2)}
          color="white"
          textDecoration={"underline"}
        >
          {" "}
          {minutesNeeded.toFixed(0)} {t("offer.play_duration2")}
        </Heading>
        <Heading fontSize={getHeightInPixels(2)} color="white">
          , {t("offer.play_duration3")}
        </Heading>
        <Heading
          fontSize={getHeightInPixels(2)}
          color="white"
          textDecoration={"underline"}
          ml={1}
        >
          {nextAvailablePayoutOption.currency}
          {nextAvailablePayoutOption.value.toFixed(2)}
        </Heading>
      </Flex>
      <Flex
        justifyContent={"space-between"}
        flex={1}
        py={getHeightInPixels(2)}
        px={getWidthInPixels(4)}
        onClick={handleOfferCardClick}
      >
        <Flex flexDir={"column"}>
          <Heading fontSize={getHeightInPixels(2.25)}>{offer.title}</Heading>
          <Text fontSize={getHeightInPixels(2)}>{offer.subTitle}</Text>
        </Flex>

        <Flex flexDir={"column"} align="flex-end">
          <Flex>
            <Heading
              fontSize={getHeightInPixels(3.5)}
              textDecoration={"underline"}
              textDecorationColor={"lightgrey"}
            >
              {GetCurrencySymbolByCode(offer.totalPayoutCurrency)}
              {offer.totalPayout?.toFixed(2)}
            </Heading>
          </Flex>
          <Text mt={0.5} fontSize={getHeightInPixels(2)}>
            {t("offer.total_payout")}
          </Text>
        </Flex>
      </Flex>
      <Flex
        mt={getHeightInPixels(0.5)}
        flex={1}
        px={getWidthInPixels(6)}
        alignItems="center"
        justify={"center"}
        pb={getHeightInPixels(2)}
        onClick={handleOfferCardClick}
      >
        <GradientButton onClick={handleOfferCardClick} animate>
          {t("offer.cta")}{" "}
          <Flex
            bgColor={"white"}
            borderRadius="full"
            p={getWidthInPixels(1.5)}
            pl={getWidthInPixels(2)}
            pr={getWidthInPixels(2)}
            ml={getWidthInPixels(4)}
            boxSize={getHeightInPixels(5)}
            alignItems="center"
            justify={"center"}
          >
            <Image
              src={isAndroidStore ? "/gplay_icon.png" : "apple_icon.webp"}
              objectFit="contain"
            />
          </Flex>
        </GradientButton>
      </Flex>
    </Flex>
  );
};

export default OfferCard;

const getShimmerColor = (index: number) => {
  if (index === 0) {
    return "#5500ff";
  }

  if (index === 1) {
    return "#ff7300";
  }

  if (index === 2) {
    return "#006fff";
  }

  return "#5500ff";
};
