/* import analytics from '@react-native-firebase/analytics'; */
/* import * as Sentry from '@sentry/react-native'; */
/* import Config from 'react-native-config'; */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  UserAnon,
  UserUpdateFcmToken,
  UserUsagePermission,
  UserWallet,
} from "src/constants/endpoints";
import {
  RequestFailedUpdate,
  RequestLoadingUpdate,
  RequestSuccessfulUpdate,
} from "src/constants/RequestStatus";
import { UpdateWalletAction } from "src/redux/User/action";
import {
  TransformUserAnon,
  TransformUserWalletResponse,
} from "src/redux/User/transformer";
import { Post } from "src/utils/api";

export function* userSaga() {
  yield takeEvery("user-fetch-user", fetchUser);
  yield takeEvery("user-update-fcm-token", updateFcmToken);
  yield takeEvery(
    "user-update-notification-permission-granted",
    updateUsagePermissionGranted
  );
  yield takeEvery("user-fetch-wallet", fetchWallet);
  /* yield takeEvery("user-persist-installed-apps", persistInstalledApps); */
  /* yield takeEvery(
    "user-persist-history-installed-apps",
    persistHistoryInstalledApps
  ); */
}

function* fetchUser(action: any) {
  try {
    yield put({
      type: "user-update-fetch-user-request-state",
      userAnonFetchRequestState: RequestLoadingUpdate,
    });
    // @ts-ignore
    const response = yield call(Post, UserAnon, action.userInfo);

    const info = TransformUserAnon(response);

    /* Sentry.configureScope(function (scope) {
      scope.setUser({ id: info.userId.toString() });
      scope.setTag('environment', Config.ENV);
    }); */

    /* analytics().setUserId(info.userId.toString()); */

    localStorage.setItem("token", info.token);

    localStorage.setItem(
      "isUsagePermissionGranted",
      JSON.stringify(info.isUsagePermissionGranted)
    );

    yield put({
      type: "user-update-user-init-info",
      userInitInfo: info,
    });
    yield put({
      type: "user-update-fetch-user-request-state",
      userAnonFetchRequestState: RequestSuccessfulUpdate,
    });
  } catch (e) {
    yield put({
      type: "user-update-fetch-user-request-state",
      userAnonFetchRequestState: RequestFailedUpdate,
    });
  }
}

function* updateFcmToken(action: any) {
  try {
    yield call(Post, UserUpdateFcmToken, { fcmToken: action.fcmToken });
  } catch (e) {}
}

/* function* persistInstalledApps(
  action: ReturnType<typeof PersistInstalledApps>
) {
  try {
    const transformedInstalls = TransformInstalledApps(action.installedApps);

    yield call(Post, UserPersistInstalledApps(), {
      installs: transformedInstalls,
    });
  } catch (e) {}
}

function* persistHistoryInstalledApps(
  action: ReturnType<typeof PersistHistoryInstalledApps>
) {
  try {
    yield call(Post, UserPersistInstalledAppsHistory(), {
      packageNames: action.historyInstalledApps,
    });
  } catch (e) {}
} */

function* fetchWallet() {
  try {
    // @ts-ignore
    const response = yield call(Post, UserWallet);
    const wallet = TransformUserWalletResponse(response);

    yield put(UpdateWalletAction(wallet));
  } catch (e) {}
}

function* updateUsagePermissionGranted(action: any) {
  try {
    yield call(
      localStorage.setItem,
      "isUsagePermissionGranted",
      JSON.stringify(action.isNotificationPermissionGranted)
    );

    yield put({
      type: "user-update-usage-permission-granted",
      isUsagePermissionGranted: action.isNotificationPermissionGranted,
    });

    yield call(Post, UserUsagePermission, {
      isUsagePermissionGranted: action.isNotificationPermissionGranted,
    });
  } catch (e) {}
}
