import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "src/components/Container";
import GradientButton from "src/components/GradientButton";
import useCustomSizing from "src/hooks/useCustomSizing";
import { isMobile } from "src/utils/size";

const Imprint = () => {
  const { getHeightInPixels } = useCustomSizing();

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback(() => {
    if (location.state && location.state.from) {
      navigate(-1);
    } else {
      navigate("/");
    }
  }, [navigate, location.state]);

  return (
    <Container>
      <Flex
        overflow={"scroll"}
        flex={1}
        flexDir="column"
        px={getHeightInPixels(2)}
        py={getHeightInPixels(isMobile ? 2 : 6)}
      >
        <Flex
          height={getHeightInPixels(12)}
          backgroundColor="purple.800"
          paddingX={getHeightInPixels(2)}
          justifyContent="space-between"
          alignItems={"center"}
          position="fixed"
          top={getHeightInPixels(2)}
          right={getHeightInPixels(2)}
          left={getHeightInPixels(2)}
        >
          <Heading color={"white"} fontSize={getHeightInPixels(5)}>
            {t("imprint")}
          </Heading>
          <CloseIcon
            onClick={goBack}
            color={"white"}
            boxSize={getHeightInPixels(2)}
          />
        </Flex>
        <Flex
          flexDir={"column"}
          py={getHeightInPixels(2)}
          pt={getHeightInPixels(14)}
        >
          <Heading as={"h2"} color="gray.50" fontSize={getHeightInPixels(2.5)}>
            Gamelight GmbH
          </Heading>
          <Text
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            Lerchenstraße 28A, 22767 <br />
            Hamburg, Germany <br />
            VAT ID No.: DE360368228
          </Text>
          <Text
            fontWeight={"bold"}
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            Liability of Contents
          </Text>
          <Text
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            As a service provider, we are responsible for our own content on
            these pages in accordance with Section 7 (1) of the German Telemedia
            Act. (TMG) According to §§ 8 to 10 TMG, we as a service provider are
            not obliged to monitor transmitted or stored third-party information
            or to investigate circumstances that indicate illegal activity.
            Obligations to remove or block the use of information according to
            general laws remain unaffected. However, liability in this regard is
            only possible from the time we become aware of a specific legal
            violation. As soon as we become aware of such violations, we will
            remove this content immediately. ​ Liability of Links Our offer
            contains links to external websites of third parties, the content of
            which we have no influence on. For this reason, we cannot accept any
            liability for this external content. The respective provider or
            operator is always responsible for the content of the linked pages.
            The linked pages were checked for possible legal violations at the
            time the link was created. No illegal content was discernible at the
            time the link was created. A permanent control of the content of the
            linked pages is not reasonable without concrete evidence of an
            infringement. As soon as we become aware of legal violations, we
            will remove such links immediately. ​ Copyright The content and
            works on these pages created or used by the site operator are
            subject to German copyright law. Duplication, editing, distribution
            and any kind of use outside the limits of copyright law require the
            consent of the respective author or creator. Downloads and copies of
            this page are only permitted for private, non-commercial use. As far
            as the content on this page was not created by the operator, the
            copyrights of third parties are respected. In particular contents of
            third parties are marked as such. Should you nevertheless become
            aware of a copyright infringement, please let us know. As soon as we
            become aware of legal violations, we will remove such content
            immediately.
          </Text>

          <Text
            fontWeight={"bold"}
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            Liability of Links
          </Text>

          <Text
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            Our offer contains links to external websites of third parties, the
            content of which we have no influence on. For this reason, we cannot
            accept any liability for this external content. The respective
            provider or operator is always responsible for the content of the
            linked pages. The linked pages were checked for possible legal
            violations at the time the link was created. No illegal content was
            discernible at the time the link was created. A permanent control of
            the content of the linked pages is not reasonable without concrete
            evidence of an infringement. As soon as we become aware of legal
            violations, we will remove such links immediately. ​ Copyright The
            content and works on these pages created or used by the site
            operator are subject to German copyright law. Duplication, editing,
            distribution and any kind of use outside the limits of copyright law
            require the consent of the respective author or creator. Downloads
            and copies of this page are only permitted for private,
            non-commercial use. As far as the content on this page was not
            created by the operator, the copyrights of third parties are
            respected. In particular contents of third parties are marked as
            such. Should you nevertheless become aware of a copyright
            infringement, please let us know. As soon as we become aware of
            legal violations, we will remove such content immediately.
          </Text>

          <Text
            fontWeight={"bold"}
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            Copyright
          </Text>

          <Text
            mt={getHeightInPixels(1)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
          >
            The content and works on these pages created or used by the site
            operator are subject to German copyright law. Duplication, editing,
            distribution and any kind of use outside the limits of copyright law
            require the consent of the respective author or creator. Downloads
            and copies of this page are only permitted for private,
            non-commercial use. As far as the content on this page was not
            created by the operator, the copyrights of third parties are
            respected. In particular contents of third parties are marked as
            such. Should you nevertheless become aware of a copyright
            infringement, please let us know. As soon as we become aware of
            legal violations, we will remove such content immediately.
          </Text>

          <Flex mt={getHeightInPixels(2)}>
            <GradientButton onClick={goBack}>{t('close')}</GradientButton>
          </Flex>
          <Text
            fontWeight={"bold"}
            mt={getHeightInPixels(4)}
            color={"gray.50"}
            fontSize={getHeightInPixels(1.75)}
            textAlign="center"
          >
            © {new Date().getFullYear()} by Gamelight.
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Imprint;
