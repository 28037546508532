export interface AppUsageState {
  clickedCampaigns: AppTrackingInfo[];
  installedCampaigns: AppTrackingInfo[];
  activeCampaigns: ActiveCampaignInfo[];
}

export const initialState: AppUsageState = {
  clickedCampaigns: [],
  installedCampaigns: [],
  activeCampaigns: [],
};

export function AppUsage(state = initialState, action: any) {
  switch (action.type) {
    case "usage-update-clicked-campaigns":
      return {
        ...state,
        clickedCampaigns: action.campaigns,
      };
    case "usage-update-installed-campaigns":
      return {
        ...state,
        installedCampaigns: action.campaigns,
      };
    case "usage-add-clicked-campaign":
      let clickedCampaigns = state.clickedCampaigns;
      clickedCampaigns.push(action.campaign);

      localStorage.setItem(
        "clickedCampaigns",
        JSON.stringify(clickedCampaigns)
      );

      return {
        ...state,
        clickedCampaigns: clickedCampaigns,
      };
    case "usage-add-installed-campaign":
      let installedCampaigns = state.installedCampaigns;
      installedCampaigns.push(action.campaign);

      localStorage.setItem(
        "installedCampaigns",
        JSON.stringify(installedCampaigns)
      );

      return {
        ...state,
        installedCampaigns: installedCampaigns,
      };
    case "usage-update-active-campaigns": {
      return {
        ...state,
        activeCampaigns: action.campaigns.campaigns,
      };
    }
    default:
      return state;
  }
}

export interface AppTrackingInfo {
  partnerCampaignId: number;
  packageName: string;
}

export interface ActiveCampaignInfo {
  iconUrl: string;
  packageName: string;
  title: string;
  playedSeconds: number;
  earnedCoins: number;
  nextCoins: number;
  secondsUntilNextCoins: number;
  challenges: ActiveCampaignChallenge[];
  finishedChallengesCount: number;
  isInstalled: boolean;
}

export interface ActiveCampaignChallenge {
  title: string;
  unit: string;
  progress: number;
  threshold: number;
  rewardCoins: number;
  rewardAmount?: number;
  rewardCurrency?: string;
  isFinished: boolean;
}
