// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";

import { analytics, messaging } from "../config/firebase";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { FetchClickedCampaigns } from "src/redux/AppUsage/action";
import {
  useOnForegroundNotificationPress,
  useOnOpenAppFromClosed,
  useRefreshFcmToken,
} from "src/hooks/FCM";
import {
  ShowRateAppReviewScreenAction,
  ShowWelcomeReward,
} from "src/redux/Notification/action";
import { RootReducer } from "src/redux";
import { FetchWalletAction } from "src/redux/User/action";
import { Get } from "src/utils/api";
import { logEvent } from "firebase/analytics";
import { onMessage } from "firebase/messaging";

const FCM_EVENT_TYPE = "eventType";

const filteredMessagesFromDisplay: string[] = [];

const FcmTypeActions: {
  [type: string]: (dispatch: Dispatch, data: { [key: string]: string }) => void;
} = {
  gift_welcome: (dispatch) => {
    setTimeout(() => {
      dispatch(ShowWelcomeReward());
    }, 500);
  },
  coins_change: (dispatch) => {
    dispatch(FetchWalletAction());
  },
  /*   coins_boost_active: (dispatch) => {
    dispatch(FetchCoinBoost(true));
  }, */
  /* usage_permission: (dispatch) => {
    dispatch(ShowUsagePermissionRequest());
  }, */
  refresh_clicked_campaigns: (dispatch) => {
    dispatch(FetchClickedCampaigns());
  },
  /* rate_app: (dispatch) => {
    dispatch(ShowRateAppScreenAction());
  }, */
  rate_app_review: (dispatch) => {
    dispatch(ShowRateAppReviewScreenAction());
  },
  firebase_event: (_, data) => {
    if (data.eventName) {
      if (data.value && data.currency) {
        logEvent(analytics, data.eventName, {
          value: data.value,
          currency: data.currency,
        });
      } else {
        logEvent(analytics, data.eventName);
      }
    }
  },
  http_get_request: (dispatch, data) => {
    if (data && data.url) {
      Get(data.url);
    }
  },
};

const FcmTypeForward: {
  [type: string]: (dispatch: Dispatch, data: { [key: string]: string }) => void;
} = {
  /* partner_app_install: (_, data) => {
    AppUsageModule.openPartnerApp(data.packageName);
  }, */
  /* install_reminder: (dispatch, data) => {
    AppUsageModule.openPartnerApp(data.packageName).then((error: string) => {
      if (error) {
        dispatch(SwitchRouteAction("my_apps"));
      }
    });
  }, */
  /* usage_permission: (dispatch) => {
    dispatch(ShowUsagePermissionRequest());
  }, */
};

function FcmListener() {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootReducer) => state.user.userId);
  const isUserAnon = useSelector((state: RootReducer) => state.user.userIsAnon);

  useRefreshFcmToken();

  useEffect(() => {
    if (isUserAnon) {
      return;
    }

    /* return notifee.onBackgroundEvent(async (msg) => {
      onDataMessageOpened(msg.detail, dispatch);
    }); */
  }, [dispatch, isUserAnon]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    return onMessage(messaging, async (msg) => {
      await onDisplayMessage(msg);

      if (!msg.data || !msg.data[FCM_EVENT_TYPE]) {
        return;
      }

      const msgType = msg.data[FCM_EVENT_TYPE];

      if (FcmTypeActions[msgType]) {
        const action = FcmTypeActions[msgType];

        action(dispatch, msg.data);
      }
    });
  }, [dispatch, userId, isUserAnon]);

  useOnForegroundNotificationPress();
  useOnOpenAppFromClosed();

  return null;
}

export const onDataMessageOpened = (msg: any | null, dispatch: any) => {
  if (!msg) {
    return;
  }

  const notification = msg.notification;

  let data: any;

  if (msg.data) {
    data = msg.data;
  }

  if (notification.data) {
    data = notification.data;
  }

  if (!data || !data[FCM_EVENT_TYPE]) {
    return;
  }

  const msgType = data[FCM_EVENT_TYPE];

  if (FcmTypeForward[msgType]) {
    const action = FcmTypeForward[msgType];

    setTimeout(() => {
      action(dispatch, data);
    }, 300);
  }
};

export const onDisplayMessage = async (msg: any) => {
  if (msg.data && msg.data.eventType) {
    if (filteredMessagesFromDisplay.includes(msg.data.eventType)) {
      return;
    }
  }

  if (msg.notification) {
    let notification: Notification = {
      body: "",
      data: undefined,
      dir: "auto",
      icon: "",
      lang: "",
      onclick: null,
      onclose: null,
      onerror: null,
      onshow: null,
      tag: "",
      title: "",
      close: function (): void {
        throw new Error("Function not implemented.");
      },
      addEventListener: function <K extends keyof NotificationEventMap>(
        type: K,
        listener: (this: Notification, ev: NotificationEventMap[K]) => any,
        options?: boolean | AddEventListenerOptions | undefined
      ): void {
        throw new Error("Function not implemented.");
      },
      removeEventListener: function <K extends keyof NotificationEventMap>(
        type: K,
        listener: (this: Notification, ev: NotificationEventMap[K]) => any,
        options?: boolean | EventListenerOptions | undefined
      ): void {
        throw new Error("Function not implemented.");
      },
      dispatchEvent: function (event: Event): boolean {
        throw new Error("Function not implemented.");
      },
    };
    /* android: {
        channelId,
        smallIcon: "ic_notification",
        importance: AndroidImportance.HIGH,
        color: PRIMARY_COLOR,
        pressAction: {
          id: "default",
        },
      }, */
    /* ios: {
        foregroundPresentationOptions: {
          alert: true,
          badge: true,
          sound: true,
        },
      }, */

    /* if (msg.data) {
      notification.data = msg.data;
    } */

    /* if (msg.notification.android && msg.notification.android.imageUrl) {
      notification.android = {
        ...notification.android,
        largeIcon: msg.notification.android.imageUrl,
        style: {
          type: AndroidStyle.BIGPICTURE,
          picture: msg.notification.android.imageUrl,
        },
      };
    } */

    /* if (msg.data && msg.data.fcm_options && msg.data.fcm_options.image) {
      notification.ios = {
        ...notification.ios,
        attachments: [
          {
            url: msg.data.fcm_options.image,
            thumbnailHidden: false,
          },
        ],
      };
    } */

    const full = {
      ...msg.notification,
      ...notification,
    };

    full?.data?.fcm_options && delete full.data.fcm_options;

    /* await notifee.displayNotification(full); */
  }
};

export default connect()(FcmListener);
