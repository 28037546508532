export const Offers = "/offers-engagement";
export const OffersClick = "/offers/click";

export const Usage = "/usage";
export const UsageClickedCampaigns = "/usage/campaign-clicks";
export const UsageInstalledCampaigns = "/usage/campaign-installs";
export const UsageActiveCampaigns = "/usage/active-campaigns-engagement";
export const UsageInstall = "/usage/install";
export const UsageCoinBoost = "/usage/coin-boost";

export const Payout = "/payout";
export const PayoutOptions = "/payout/options";
export const PayoutHistory = "/payout/list";

export const UserUpdate = "/user";
export const UserAnon = "/user/anon";
export const UserUpdateFcmToken = "/user/fcm";
export const UserUsagePermission = "/user/usage-permission";
export const UserWallet = "/user/wallet";
export const UserPersistInstalledApps = "/user/installed-apps";
export const UserPersistInstalledAppsHistory = "/user/installed-apps-history";

export const Reward = "/reward";
