import { useState, useEffect } from "react";

const useCustomSizing = () => {
  const [sizing, setSizing] = useState({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    isMobile: window.innerWidth < 769,
    phoneHeight: window.innerHeight * 0.8,
    phoneWidth: window.innerHeight * 0.8 * (7.5 / 16),
  });

  const [notchConstants, setNotchConstants] = useState({
    TOP_NOTCH_HEIGHT: sizing.isMobile ? 0 : 4,
    BOTTOM_NOTCH_HEIGHT: sizing.isMobile ? 0 : 0,
  });

  const [barConstants, setBarConstants] = useState({
    USER_INFO_HEADER_HEIGHT: !sizing.isMobile
      ? 12 + notchConstants.TOP_NOTCH_HEIGHT
      : 12,
    BOTTOM_BAR_HEIGHT: !sizing.isMobile
      ? notchConstants.BOTTOM_NOTCH_HEIGHT + 10
      : 10,
  });

  useEffect(() => {
    const handleResize = () => {
      const newInnerHeight = window.innerHeight;
      const newInnerWidth = window.innerWidth;
      const newIsMobile = newInnerWidth < 769;
      const newPhoneHeight = newInnerHeight * 0.8;
      const newPhoneWidth = newPhoneHeight * (7.5 / 16);

      setSizing({
        innerHeight: newInnerHeight,
        innerWidth: newInnerWidth,
        isMobile: newIsMobile,
        phoneHeight: newPhoneHeight,
        phoneWidth: newPhoneWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setNotchConstants({
      TOP_NOTCH_HEIGHT: sizing.isMobile ? 0 : 4,
      BOTTOM_NOTCH_HEIGHT: sizing.isMobile ? 0 : 0,
    });
  }, [sizing.isMobile]);

  useEffect(() => {
    setBarConstants({
      USER_INFO_HEADER_HEIGHT: !sizing.isMobile
        ? 12 + notchConstants.TOP_NOTCH_HEIGHT
        : 12,
      BOTTOM_BAR_HEIGHT: !sizing.isMobile
        ? notchConstants.BOTTOM_NOTCH_HEIGHT + 10
        : 10,
    });
  }, [
    notchConstants.BOTTOM_NOTCH_HEIGHT,
    notchConstants.TOP_NOTCH_HEIGHT,
    sizing.isMobile,
  ]);

  const getHeightInPixels = (h: number) => {
    const oneHeightUnit =
      (sizing.isMobile ? sizing.innerHeight : sizing.phoneHeight) / 100;
    return h * oneHeightUnit + "px";
  };

  const getWidthInPixels = (w: number) => {
    const oneWidthUnit =
      (sizing.isMobile ? sizing.innerWidth : sizing.phoneWidth) / 100;
    return w * oneWidthUnit + "px";
  };

  return {
    ...sizing,
    ...notchConstants,
    ...barConstants,
    getHeightInPixels,
    getWidthInPixels,
  };
};

export default useCustomSizing;
