/* import { Linking } from "react-native"; */

import * as uuid from "uuid";
import { call, put, takeLatest } from "redux-saga/effects";

import { Offers, OffersClick } from "src/constants/endpoints";
import {
  RequestFailedUpdate,
  RequestLoadingUpdate,
  RequestSuccessfulUpdate,
} from "src/constants/RequestStatus";
import { AddToClickedCampaignsAction } from "src/redux/AppUsage/action";
import {
  PersistOfferClickAction,
  UpdateFetchOffersRequestState,
  UpdateOffersAction,
} from "src/redux/Offers/action";
import { TransformFetchOffersResponse } from "src/redux/Offers/transformer";
import { Post } from "src/utils/api";

export function* offersSaga() {
  yield takeLatest("offers-fetch", fetchOffers);
  yield takeLatest("offers-persist-click-offer", clickOffer);
}

function* fetchOffers() {
  try {
    yield put(UpdateFetchOffersRequestState(RequestLoadingUpdate));

    // @ts-ignore
    const response = yield call(Post, Offers);

    yield put(UpdateOffersAction(TransformFetchOffersResponse(response)));

    yield put(UpdateFetchOffersRequestState(RequestSuccessfulUpdate));
  } catch (e) {
    yield put(UpdateFetchOffersRequestState(RequestFailedUpdate));
    console.log("error");
  }
}

function* clickOffer(action: ReturnType<typeof PersistOfferClickAction>) {
  try {
    yield put(
      AddToClickedCampaignsAction({
        partnerCampaignId: action.partnerCampaignId,
        packageName: action.packageName,
      })
    );

    if (action.isClickForward) {
      let clickUrl = action.clickUrl;
      let clickId = null;

      if (clickUrl.includes("{click_id}")) {
        clickId = uuid.v4().toString();
        clickUrl = clickUrl.replace("{click_id}", clickId);
      }

      yield call(Post, OffersClick, {
        partnerCampaignId: action.partnerCampaignId,
        packageName: action.packageName,
        clickId: clickId,
      });

      if (action.packageName.includes(".")) {
        window.open(clickUrl, "_blank");
      } else {
        setTimeout(() => {
          window.open(clickUrl, "_top");
        });
      }
    } else {
      yield call(Post, OffersClick, {
        partnerCampaignId: action.partnerCampaignId,
        packageName: action.packageName,
      });

      window.open(`https://play.google.com/store/apps/details?id=${action.packageName}`, "_blank");
    }
  } catch (e) {}
}
