import React, {useEffect, useRef, useState} from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";

import { GetNextAvailableCoinsPayout } from "src/redux/Payout/reducer";
import { RootReducer } from "src/redux/index";
import usePrevious from "src/hooks/usePrevious";
import { Flex, Heading, Skeleton, Text } from "@chakra-ui/react";
import { hexToRGB } from "src/utils/color";
import { GRADIENT_BUTTON_1 } from "src/constants/colors";
import Coin from "../assets/animations/coin-animated.json";
import LottieView from "lottie-react";
import useCustomSizing from "src/hooks/useCustomSizing";

export function UserInfoHeader() {
  const {
    getHeightInPixels,
    getWidthInPixels,
    TOP_NOTCH_HEIGHT,
    USER_INFO_HEADER_HEIGHT,
  } = useCustomSizing();
  const wallet = useSelector((state: RootReducer) => state.user.wallet);
  const payoutOptionCategories = useSelector(
    (state: RootReducer) => state.payout.payoutOptionCategories
  );

  const lottieRef = useRef<any>(null);

  const payoutOptions =
    payoutOptionCategories && payoutOptionCategories.length > 0
      ? payoutOptionCategories[0].payoutOptions
      : [];

  const coins = wallet ? wallet.coins : 0;
  const previousCoins = usePrevious(coins);

  const nextAvailablePayout = GetNextAvailableCoinsPayout(
    wallet,
    payoutOptions
  );

  const nextCoinsPayout = nextAvailablePayout ? nextAvailablePayout.coins : 1;

  const [width, setWidth] = useState<string>((0).toString());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWidth(
        (
          (Math.min(wallet?.coins ?? 0, nextCoinsPayout) / nextCoinsPayout) *
          100
        ).toString()
      );
    }, 200);

    return () => clearTimeout(timeout);
  }, [nextCoinsPayout, wallet?.coins]);

  useEffect(() => {
    if (!wallet) {
      return;
    }

    /* onAnimate(
      (Math.min(wallet.coins, nextCoinsPayout) / nextCoinsPayout) * 100
    ); */
  }, [wallet, nextCoinsPayout]);

  /* const coinChange = coins - (previousCoins ? previousCoins : 0); */

  return (
    <Flex
      width={getWidthInPixels(100)}
      bgGradient={"linear(to-r, #7B00FF, #7B00FF, #4000ff)"}
      px={getWidthInPixels(4)}
      pointerEvents={"none"}
      height={getHeightInPixels(USER_INFO_HEADER_HEIGHT)}
      flexDir={"column"}
      justify="center"
      top={0}
      zIndex={999}
      pt={getHeightInPixels(TOP_NOTCH_HEIGHT)}
    >
      {nextAvailablePayout ? (
        <>
          <Heading
            color={"white"}
            mb={getHeightInPixels(1)}
            fontSize={getHeightInPixels(2.3)}
            marginBottom={2}
            textAlign="center"
            textShadow={`0.5px 0.5px ${hexToRGB(GRADIENT_BUTTON_1, 0.5)}`}
          >
            {i18next.t("payout.next_payout")}
            <>
              {" "}
              {nextAvailablePayout.currency}
              {nextAvailablePayout.value.toFixed(2)}
            </>
          </Heading>
          <Flex alignItems="center" justify={"center"}>
            <Flex
              alignItems="center"
              borderRadius={getHeightInPixels(5)}
              height={getHeightInPixels(4)}
              bgColor={"rgb(223,202,253)"}
              w="full"
              position={"relative"}
            >
              <Flex
                borderRadius={getHeightInPixels(5)}
                height={getHeightInPixels(4)}
                bgColor={"#ff0063"}
                alignSelf="flex-start"
                transition={"all 1s ease"}
                w={`${width}%`}
              />
              <Flex
                pos={"absolute"}
                width="full"
                alignItems="center"
                justifyContent={"center"}
                zIndex={999}
                height={getHeightInPixels(4)}
              >
                <Text
                  fontWeight={"bold"}
                  fontSize={getHeightInPixels(2.25)}
                  color="white"
                  textAlign={"center"}
                  textShadow="0.7px 0.7px purple"
                  noOfLines={1}
                >
                  {coins}{" "}
                  {nextAvailablePayout ? `/ ${nextAvailablePayout.coins}` : ""}{" "}
                  {i18next.t("coins")}
                </Text>

                <LottieView
                  animationData={Coin}
                  autoPlay={true}
                  loop
                  lottieRef={lottieRef}
                  onDOMLoaded={() => lottieRef.current?.setSpeed(0.6)}
                  style={{
                    display: "flex",
                    width: getWidthInPixels(6),
                    marginLeft: getWidthInPixels(4),
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Skeleton height={getHeightInPixels(2)} mb={2} borderRadius="5vh" />
          <Skeleton height={getHeightInPixels(5)} borderRadius="5vh" />
        </>
      )}
    </Flex>
  );
}
