import AppRouter from "./routes/AppRouter";
import AuthRouter from "./routes/AuthRouter";
import { setI18nConfig } from "./utils/translate";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from "redux";
import rootReducer, { RootReducer } from "./redux/index";
import { userCreateSaga } from "src/redux/UserCreate/saga";
import { userSaga } from "src/redux/User/saga";
import { GatherUserInfo, useInitUser } from "src/hooks/User";
import { useDispatch, useSelector } from "react-redux";
import { RequestStatus } from "src/types/Request";
import { useEffect } from "react";
import { UserBaseData } from "src/types/User";
import { FetchUserAction } from "src/redux/UserCreate/action";
import { offersSaga } from "src/redux/Offers/saga";
import { payoutSaga } from "src/redux/Payout/saga";
import { usageSaga } from "src/redux/AppUsage/saga";
import { payoutHistorySaga } from "src/redux/PayoutHistory/saga";
import { rewardSaga } from "src/redux/Reward/saga";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import GradientContainer from "src/components/GradientContainer";
import { t } from "i18next";
import useCustomSizing from "src/hooks/useCustomSizing";
import { UpdateIsNotificationPermissionGrantedAction } from "src/redux/User/action";
import { isSupported } from "firebase/messaging";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(offersSaga);
sagaMiddleware.run(payoutSaga);
sagaMiddleware.run(payoutHistorySaga);
sagaMiddleware.run(usageSaga);
sagaMiddleware.run(userCreateSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(rewardSaga);

function App() {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const dispatch = useDispatch();

  setI18nConfig();
  useInitUser();

  const isUserCreateDone: boolean = useSelector(
    (state: RootReducer) => state.userCreate.isUserCreated
  );
  const isUserAnon: boolean = useSelector(
    (state: RootReducer) => state.user.userIsAnon
  );
  const isUserAnonRequestSuccessful: boolean = useSelector(
    (state: RootReducer) => state.user.userAnonFetchRequestState.isSuccessful
  );

  const createUserRequestState: RequestStatus = useSelector(
    (state: RootReducer) => state.userCreate.userCreateUpdateRequestState
  );

  useEffect(() => {
    const checkNotificationPermission = async () => {
      const notificationSupported = await isSupported();
      dispatch(
        UpdateIsNotificationPermissionGrantedAction(
          Notification.permission !== "default" || !notificationSupported
        )
      );
    };

    const getIP = async () => {
      try {
        const res = await fetch("https://api.ipify.org?format=json", {
          method: "GET",
        });
        const json = await res.json();
        localStorage.setItem("user-ip", json.ip);
      } catch (error) {
        localStorage.removeItem("user-ip");
        return "";
      }
    };

    checkNotificationPermission();
    getIP();
  }, [dispatch]);

  useEffect(() => {
    if (createUserRequestState.isSuccessful) {
      GatherUserInfo()
        .then((userInfo: UserBaseData) => {
          dispatch(FetchUserAction(userInfo));
        })
        .catch((e) => console.log("e: ", e));
    }
  }, [createUserRequestState, dispatch]);

  if (!isUserAnonRequestSuccessful) {
    return (
      <GradientContainer>
        <Flex
          flex={1}
          alignItems="center"
          justify={"center"}
          flexDir="column"
          px={getWidthInPixels(10)}
        >
          <Text
            fontSize={getHeightInPixels(6)}
            color="white"
            fontWeight={"bold"}
            align="center"
          >
            {t("init.welcome")}
          </Text>
          <Text
            fontSize={getHeightInPixels(3)}
            color="white"
            fontWeight={"medium"}
            align="center"
          >
            {t("init.loading")}
          </Text>
          <Flex>
            <Spinner
              mt={getHeightInPixels(4)}
              boxSize={getHeightInPixels(10)}
              color={"#f3f3f3"}
              size="large"
            />
          </Flex>
        </Flex>
      </GradientContainer>
    );
  }

  // if (!isUserCreateDone && isUserAnonRequestSuccessful && isUserAnon) {
  //   return <AuthRouter />;
  // }

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
