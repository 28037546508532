import { Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import BottomBar from "src/components/BottomBar";
import Container from "src/components/Container";
import { UserInfoHeader } from "src/components/UserInfoHeader";

const AppLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Container>
      <UserInfoHeader />
      <Flex
        flex={1}
        flexDir="column"
        overflow={"hidden"}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
        position="relative"
      >
        {children}
      </Flex>
      <BottomBar />
    </Container>
  );
};

export default AppLayout;
