import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import OfferCard from "src/components/OfferCard";
import { RootReducer } from "src/redux";
import { GetNextAvailableCoinsPayout } from "src/redux/Payout/reducer";
import useCustomSizing from "src/hooks/useCustomSizing";
import {
  ShowOfferOnTopAction,
} from "src/redux/Notification/action";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { GRADIENT_BUTTON_2 } from "src/constants/colors";
import GradientButton from "src/components/GradientButton";
import { useCallback, useEffect } from "react";

const Home = () => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const wallet = useSelector((state: RootReducer) => state.user.wallet);
  const payoutOptionCategories = useSelector(
    (state: RootReducer) => state.payout.payoutOptionCategories
  );
  const payoutOptions =
    payoutOptionCategories && payoutOptionCategories.length > 0
      ? payoutOptionCategories[0].payoutOptions
      : [];

  const offers = useSelector((state: RootReducer) => state.offers.offers);
  const offersRequestState = useSelector((state:RootReducer) => state.offers.fetchOffersRequestState);
  const nextAvailablePayoutOption = GetNextAvailableCoinsPayout(
    wallet,
    payoutOptions
  );

  const checkIsFirstTime = useCallback((): boolean => {
    const storageItem = localStorage.getItem("playtime-first-time");
    return !storageItem;
  }, []);

  const handleFirstTime = useCallback(() => {
    localStorage.setItem("playtime-first-time", JSON.stringify(true));
    onOpen();
  }, [onOpen]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const isFirstTime = checkIsFirstTime();
    if (isFirstTime) {
      timeout = setTimeout(() => {
        handleFirstTime();
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [checkIsFirstTime, handleFirstTime]);

  return (
    <Flex
      flex={1}
      flexDir="column"
      py={getHeightInPixels(2)}
      px={getWidthInPixels(4)}
      pb={getHeightInPixels(4)}
      gap={getHeightInPixels(4)}
      overflow={"scroll"}
      backgroundColor="white"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size={"lg"} color="white" />
          <Flex
            flexDir={"column"}
            bgGradient={"linear(to-b, #9826f8, #7e00e8, #5424fb)"}
            w={getWidthInPixels(100)}
            h={getHeightInPixels(100)}
            alignItems="center"
            py={getHeightInPixels(4)}
          >
            <Flex flex={1} />
            <Heading
              fontSize={getHeightInPixels(5)}
              color={"white"}
              fontWeight={"bold"}
            >
              {t("onboarding.headline")}
            </Heading>
            <Heading
              color={"white"}
              fontSize={getHeightInPixels(3.5)}
              fontWeight={"medium"}
              as={"h2"}
            >
              {t("onboarding.headline_2")}
            </Heading>

            <Text
              fontSize={getHeightInPixels(2.5)}
              marginTop={getHeightInPixels(2)}
              fontWeight={"bold"}
              textAlign="center"
              as={"span"}
              color={"white"}
              marginX={getWidthInPixels(10)}
            >
              {t("onboarding.sub")}
              <Text
                as={"span"}
                fontWeight="black"
                color={"white"}
                backgroundColor={GRADIENT_BUTTON_2}
                px={getWidthInPixels(2)}
                py={getHeightInPixels(0.75)}
                borderRadius={getWidthInPixels(2)}
              >
                $120.00
              </Text>
              {t("onboarding.sub_2")}
            </Text>
            <Image
              my={getHeightInPixels(3)}
              src="/onboarding-ss.png"
              h={getHeightInPixels(60)}
            />
            <Flex
              mt={getHeightInPixels(0.5)}
              w="full"
              px={getWidthInPixels(10)}
              alignItems="center"
              justify={"center"}
              onClick={onClose}
            >
              <GradientButton onClick={onClose} animate>
                {t("onboarding.cta")}
                {/*  <Flex
                  bgColor={"white"}
                  borderRadius="full"
                  p={getWidthInPixels(2)}
                  ml={getWidthInPixels(4)}
                  boxSize={getHeightInPixels(4)}
                  alignItems="center"
                  justify={"center"}
                  >
                  <Image
                  src={isAndroidStore ? "/gplay_icon.png" : "apple_icon.webp"}
                  objectFit="contain"
                  />
                </Flex> */}
              </GradientButton>
            </Flex>
          </Flex>
          {/* <Image
              src="/onboarding.png"
              w={getWidthInPixels(100)}
              h={getHeightInPixels(100)}
            /> */}
        </ModalContent>
      </Modal>
      {offers.map((offer, index) => (
        <OfferCard
          key={offer.partnerCampaignId}
          offer={offer}
          index={index}
          nextAvailablePayoutOption={nextAvailablePayoutOption}
          wallet={wallet}
          onPress={() => {
            dispatch(ShowOfferOnTopAction(offer));
          }}
        />
      ))}
      {!offersRequestState.isLoading && (
      <Text
        mt={getHeightInPixels(0)}
        align="center"
        textAlign={"center"}
        textDecorationLine={"underline"}
        fontWeight="bold"
      >
        <Link to="/imprint">{t("imprint")}</Link>
      </Text>
      )}
    </Flex>
  );
};

export default Home;
