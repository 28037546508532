import { RequestStatus } from "src/types/Request";
import { UserBaseData, UserCreateUserData } from "src/types/User";

export const FetchUserAction = (userInfo: UserBaseData) => {
  return {
    type: "user-fetch-user",
    userInfo: userInfo,
  };
};

export const UpdateUserInfoAction = (userData: UserCreateUserData) => {
  return {
    type: "user-create-update-user-info",
    userInfo: {
      genderId: userData.genderId,
      age: userData.age,
    },
  };
};

export const UpdateUserInfoRequestStateAction = (
  requestState: RequestStatus
) => {
  return {
    type: "user-create-update-info-request-state",
    requestState: requestState,
  };
};
