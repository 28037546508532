import { PayoutOptionCategory } from "src/redux/Payout/reducer";

export const TransformFetchPayoutOptionsRequestState = (
  apiResponse: FetchPayoutOptionsResponse
): PayoutOptionCategory[] => {
  let transformed: PayoutOptionCategory[] = [];

  apiResponse.categories.forEach((c) => {
    transformed.push(c);
  });

  return transformed;
};

interface FetchPayoutOptionsResponse {
  categories: FetchPayoutOptionsCategoryResponse[];
}

interface FetchPayoutOptionsCategoryResponse {
  category: string;
  name: string;
  payoutOptions: FetchPayoutOptionsResponseOption[];
}

interface FetchPayoutOptionsResponseOption {
  value: number;
  currency: string;
  category: string;
  coins: number;
  imageUrl: string;
}
