import { useCallback, useEffect } from "react";

import { getToken } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";

import { RootReducer } from "src/redux";
import {
  UpdateFcmTokenAction,
  UpdateIsNotificationPermissionGrantedAction,
} from "src/redux/User/action";
import { messaging } from "src/config/firebase";

export const useRefreshFcmToken = () => {
  const { isNotificationPermissionGranted } = useSelector(
    (state: RootReducer) => state.user
  );
  const dispatch = useDispatch();

  const retrieveTokenWithGrantingPermission = useCallback(async () => {
    navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then(async (register) => {
        const permissionResult = await Notification.requestPermission();

        if (permissionResult === "granted") {
          const token = await getToken(messaging, {
            vapidKey:
              "BAFd9DG03ZV3AoE2Q10RSPkZgPNstPx3ALpKuf0xi1-GMOKwTrblAmQ6xKAww9a0h6GFuYbNYmAO7_E3GiI2R1M",
          });

          dispatch(UpdateFcmTokenAction(token));
          dispatch(
            UpdateIsNotificationPermissionGrantedAction(
              permissionResult === "granted"
            )
          );

          return token;
        }
        dispatch(UpdateIsNotificationPermissionGrantedAction(true));
        return "";
      })
      .catch((e) => console.log(e));
  }, [dispatch]);

  // fcm token update on startup happens in user anon request
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        UpdateIsNotificationPermissionGrantedAction(
          Notification.permission !== "default"
        )
      );
    }, 1500);
  }, [dispatch]);

  // register listener for changes during active app
  useEffect(() => {
    isNotificationPermissionGranted && retrieveTokenWithGrantingPermission();

    /* return messaging().onTokenRefresh((token) => {
      dispatch(UpdateFcmTokenAction(token));
    }); */
  }, [isNotificationPermissionGranted, retrieveTokenWithGrantingPermission]);
};

export const useOnOpenAppFromClosed = () => {
  const dispatch = useDispatch();
  const isUserAnon = useSelector((state: RootReducer) => state.user.userIsAnon);

  useEffect(() => {
    if (isUserAnon) {
      return;
    }

    /* messaging()
      .getInitialNotification()
      .then((msg) => onDataMessageOpened(msg, dispatch)); */
  }, [dispatch, isUserAnon]);

  //not sure if this actually still used but it should be okay to keep it
  useEffect(() => {
    if (isUserAnon) {
      return;
    }

    /* notifee
      .getInitialNotification()
      .then((msg) => onDataMessageOpened(msg, dispatch)); */
  }, [dispatch, isUserAnon]);
};

export const useOnForegroundNotificationPress = () => {
  const dispatch = useDispatch();
  const isUserAnon = useSelector((state: RootReducer) => state.user.userIsAnon);

  useEffect(() => {
    if (isUserAnon) {
      return;
    }

    /* return notifee.onForegroundEvent(({ type, detail }) => {
      switch (type) {
        case EventType.PRESS:
          onDataMessageOpened(detail, dispatch);
      }
    }); */
  }, [dispatch, isUserAnon]);
};
