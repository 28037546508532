import React, {CSSProperties, useRef} from "react";

import LottieView, {LottieRef} from "lottie-react";
import Coin from "../assets/animations/coin-animated.json";
import useCustomSizing from "src/hooks/useCustomSizing";

type CoinLottieViewProps = {
  styles?: CSSProperties;
};

function CoinLottieView(props: CoinLottieViewProps) {
  const { getWidthInPixels } = useCustomSizing();
  const lottieRef = useRef<any>(null);

  return (
    <LottieView
      animationData={Coin}
      lottieRef={lottieRef}
      onDOMLoaded={() => lottieRef.current?.setSpeed(0.6)}
      autoPlay={true}
      loop
      style={{
        display: "flex",
        width: getWidthInPixels(8),
        ...props.styles,
      }}
    />
  );
}

export default CoinLottieView;
