import { CalendarIcon } from "@chakra-ui/icons";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { PURPLE } from "src/constants/colors";
import { PayoutHistoryType } from "src/redux/PayoutHistory/reducer";

type PayoutHistoryItemProps = {
  item: PayoutHistoryType;
};

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

const PayoutHistoryItem = ({ item }: PayoutHistoryItemProps) => {
  const [isCopied, setCopied] = useState<boolean>(false);

  const handleCopy = useCallback(async () => {
    await copyTextToClipboard(item.securityCode ?? "");
    setCopied(true);
  }, [item]);

  useEffect(() => {
    //make sure redeem code button turns to disabled state after closing modal
    setCopied(false);
  }, []);

  return (
    <Flex
      width={"full"}
      borderRadius={10}
      backgroundColor="white"
      boxShadow={"md"}
      flexDir="column"
      mb={8}
      p={4}
    >
      <Flex width={"full"} justifyContent="space-between" mb={4}>
        <Text fontWeight={"bold"} fontSize={16} color="gray.700">
          {item.currency}
          {item.value.toFixed(2)} {t("payout.payout")}
        </Text>
        <Flex alignItems={"center"}>
          <Text fontWeight={"bold"} fontSize={16} color="gray.700">
            {item.createdAt}
          </Text>
          <CalendarIcon boxSize={"16px"} ml={2} />
        </Flex>
      </Flex>
      <Flex w={"full"} justifyContent="space-between">
        <Flex flex={1} h="10vh" objectFit={"cover"}>
          <Image src={item.imageUrl} />
        </Flex>
        <Flex flex={3} flexDir={"column"} alignItems="flex-end">
          {item.status === "requested" ? (
            <Button
              backgroundColor={PURPLE}
              _focus={{ backgroundColor: PURPLE, opacity: 0.6 }}
              _pressed={{ backgroundColor: PURPLE, opacity: 0.4 }}
              color="white"
              disabled
              fontSize={12}
            >
              {t("payout.processing_button")}
            </Button>
          ) : (
            <>
              {item.securityCode !== undefined && (
                <Button
                  backgroundColor={PURPLE}
                  _pressed={{ backgroundColor: PURPLE, opacity: 0.4 }}
                  _focus={{ backgroundColor: PURPLE, opacity: 0.6 }}
                  color="white"
                  fontSize={12}
                  onClick={handleCopy}
                  mb={4}
                >{`1. ${t("payout.copy_security_code")}`}</Button>
              )}
              <Button
                backgroundColor={PURPLE}
                _focus={{ backgroundColor: PURPLE, opacity: 0.6 }}
                _pressed={{ backgroundColor: PURPLE, opacity: 0.4 }}
                color="white"
                fontSize={12}
                onClick={() => {
                  window.open(item.redemptionUrl ?? "", "_blank");
                }}
                isDisabled={item.securityCode !== undefined ? !isCopied : true}
              >{`${item.securityCode !== undefined ? "2. " : ""}${t(
                "payout.redeem_code"
              )}`}</Button>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PayoutHistoryItem;
