/* import { EMPTY_SCREEN_TYPE } from '@components/listeners/Modal'; */
import { EMPTY_SCREEN_TYPE } from "src/listeners/ModalListener";
import { RequestStatus } from "src/types/Request";

export interface CoinBoost {
  untilTime: number;
}

export interface NotificationListenerState {
  notificationQueue: NotificationPopup[];
  coinBoost: CoinBoost | null;
  fetchCoinBoostRequestState: RequestStatus;
}

export const initialState: NotificationListenerState = {
  notificationQueue: [],
  coinBoost: null,
  fetchCoinBoostRequestState: {
    isFailed: false,
    isLoading: false,
    isSuccessful: false,
  },
};

export function NotificationListener(state = initialState, action: any) {
  switch (action.type) {
    case "notification-terminal-show-type":
      if (!action.shouldOverwrite) {
        let queue = [...state.notificationQueue];

        const popupNotification = {
          type: action.notificationType,
          params: action.params,
        };

        if (action.notificationType !== null) {
          const exists = queue.find((item) => {
            return JSON.stringify(item) === JSON.stringify(popupNotification);
          });

          if (!exists) {
            queue.push(popupNotification);
            queue.push({ type: EMPTY_SCREEN_TYPE, params: null });
          }
        } else {
          queue.shift();
        }

        return {
          ...state,
          notificationQueue: queue,
        };
      } else {
        return {
          ...state,
          notificationQueue: [
            {
              type: action.notificationType,
              params: action.params,
            },
          ],
        };
      }
    case "notification-terminal-clear":
      return {
        ...state,
        notificationQueue: [],
      };

    case "notification-persist-boost-finished":
      return {
        ...state,
        coinBoost: null,
      };

    case "notification-update-coin-boost":
      return {
        ...state,
        coinBoost: action.coinBoost,
      };

    case "notification-update-fetch-coin-boost-request-state":
      return {
        ...state,
        fetchCoinBoostRequestState: action.requestState,
      };
    default:
      return state;
  }
}

export interface NotificationPopup {
  type: string;
  params: object | null;
}
