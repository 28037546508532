import { UserWallet } from "src/redux/User/reducer";

export const TransformUserAnon = (
  apiResponse: IUserAnonResponse
): IUserInitInfo => {
  return {
    userId: apiResponse.userId,
    userUuid: apiResponse.userUuid,
    token: apiResponse.token,
    isAnon: apiResponse.isAnon,
    isUsagePermissionGranted: apiResponse.isUsagePermissionGranted,
    showOfferInstallStep: apiResponse.showOfferInstallStep,
    showCoinsPage: apiResponse.showCoinsPage,
  };
};

export const TransformUserWalletResponse = (
  apiResponse: UserWalletResponse
): UserWallet => {
  return {
    coins: apiResponse.coins,
  };
};

interface IUserAnonResponse {
  userId: number;
  userUuid: string;
  token: string;
  isAnon: boolean;
  isUsagePermissionGranted: boolean;
  showOfferInstallStep: boolean;
  showCoinsPage: boolean;
}

export interface IUserInitInfo {
  userId: number;
  userUuid: string;
  token: string;
  isAnon: boolean;
  isUsagePermissionGranted: boolean;
  showOfferInstallStep: boolean;
  showCoinsPage: boolean;
}

export interface TransformedInstalledApps {
  packageName: string;
  firstInstallTimeTimestamp: number;
}

export interface UserWalletResponse {
  coins: number;
}
