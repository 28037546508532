import { Offer } from "src/redux/Offers/reducer";
import { RequestStatus } from "src/types/Request";

export const UpdateFetchOffersRequestState = (requestState: RequestStatus) => {
  return {
    type: "offers-update-fetch-offers-request-state",
    requestState: requestState,
  };
};

export const FetchOffers = () => {
  return {
    type: "offers-fetch",
  };
};

export const UpdateOfferVideoStatus = (value: boolean) => {
  return {
    type: "offers-update-video-status",
    isVideoPlaying: value,
  };
};

export const UpdateOffersAction = (offers: Offer[]) => {
  return {
    type: "offers-update-offers",
    offers: offers,
  };
};

export const PersistOfferClickAction = (
  partnerCampaignId: number,
  packageName: string,
  clickUrl: string,
  isClickForward: boolean
) => {
  return {
    type: "offers-persist-click-offer",
    partnerCampaignId: partnerCampaignId,
    packageName: packageName,
    clickUrl: clickUrl,
    isClickForward: isClickForward,
  };
};
