import React, { ReactNode, useCallback, useEffect } from "react";
import {
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { CloseNotificationAction } from "src/redux/Notification/action";
import { CloseIcon } from "@chakra-ui/icons";
import { subscribe, unsubscribe } from "src/listeners/ModalEvents";
import useCustomSizing from "src/hooks/useCustomSizing";

export interface BaseModalProps {
  children: ReactNode;
  closeAction?: () => void;
  headerImage?: string;
}

function BaseModal(props: BaseModalProps) {
  const { children, closeAction = () => {}, headerImage } = props;
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();

  const dispatch = useDispatch();
  const { onClose } = useDisclosure();

  const handleClose = useCallback(() => {
    onClose();
    dispatch(CloseNotificationAction());
    closeAction();
  }, [closeAction, dispatch, onClose]);

  useEffect(() => {
    subscribe("closeModal", handleClose);

    return () => unsubscribe("closeModal", handleClose);
  }, [handleClose]);

  return (
    <Modal isOpen={true} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        overflow={"scroll"}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
        mx={getWidthInPixels(6)}
        h={"fit-content"}
        maxH={getHeightInPixels(90)}
        borderRadius={getWidthInPixels(5)}
        pos="relative"
      >
        <CloseIcon
          onClick={handleClose}
          pos={"absolute"}
          right={4}
          top={"24px"}
          color="white"
          cursor={"pointer"}
          boxSize={"16px"}
          zIndex={9999}
        />
        {headerImage && (
          <Image
            src={headerImage}
            width="100%"
            height={getHeightInPixels(25)}
            objectFit="cover"
          />
        )}
        {children}
      </ModalContent>
    </Modal>
  );
}

export default BaseModal;
