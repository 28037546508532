import { ReactNode } from "react";
import AuthPage from "src/pages/Auth";
import HomePage from "src/pages/Home";
import MyGamesPage from "src/pages/MyGames";
import PayoutPage from "src/pages/Payout";

type Route = {
  title: string;
  path: string;
  component: ReactNode;
  id: string;
};

export const AppRoutes = [
  { title: "Home", path: "/", component: HomePage, id: "home-path" },
  {
    title: "My Games",
    path: "/my-games",
    component: MyGamesPage,
    id: "my-games-path",
  },
  {
    title: "Payout",
    path: "/payout",
    component: PayoutPage,
    id: "payout-path",
  },
];

export const AuthRoutes = [
  { title: "Register", path: "/", component: AuthPage, id: "auth-path" },
];
