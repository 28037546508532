import React from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { CloseNotificationAction } from "src/redux/Notification/action";
import { RootReducer } from "src/redux";
import ReferralModal, {
  REFERRAL_OFFER_TYPE,
} from "src/components/modals/ReferralModal";
import WelcomeGiftModal, {
  WELCOME_REWARD_TYPE,
} from "src/components/modals/WelcomeGiftModal";
import PayoutRequestModal, {
  PAYOUT_REQUEST_TYPE,
} from "src/components/modals/PayoutRequestModal";
import RateAppReviewModal, {
  RATE_APP_REVIEW_TYPE,
} from "src/components/modals/RateAppReviewModal";
import NotificationPermissionModal, {
  NOTIFICATION_PERMISSION_REQUEST_TYPE,
} from "src/components/modals/NotificationPermissionModal";
import OfferOnTopModal, {
  OFFER_ON_TOP_TYPE,
} from "src/components/modals/OfferOnTopModal";

export const EMPTY_SCREEN_TYPE = "empty";

const modals: { [key: string]: any } = {
  [EMPTY_SCREEN_TYPE]: <div />,
  /* [RATE_APP_TYPE]: NotificationRateApp, */
  [RATE_APP_REVIEW_TYPE]: RateAppReviewModal,
  [REFERRAL_OFFER_TYPE]: ReferralModal,
  /* [COIN_BOOST_TYPE]: CoinBoost, */
  [WELCOME_REWARD_TYPE]: WelcomeGiftModal,
  [PAYOUT_REQUEST_TYPE]: PayoutRequestModal,
  [NOTIFICATION_PERMISSION_REQUEST_TYPE]: NotificationPermissionModal,
  [OFFER_ON_TOP_TYPE]: OfferOnTopModal,
};

export function ModalListener() {
  const dispatch = useDispatch();

  const notificationQueue = useSelector(
    (state: RootReducer) => state.notificationListener.notificationQueue
  );

  const currentModal =
    notificationQueue.length > 0 ? notificationQueue[0] : null;

  if (!currentModal) {
    return null;
  }

  if (currentModal.type === EMPTY_SCREEN_TYPE) {
    setTimeout(() => {
      dispatch(CloseNotificationAction());
    }, 300);

    return null;
  }

  const Component = modals[currentModal.type];

  if (!Component) {
    return null;
  }

  return <Component params={currentModal.params} />;
}

export default connect()(ModalListener);
