import { UserCreateUserData } from "../../types/User";
import { RequestStatus } from "../../types/Request";

export interface UserCreateState {
  isUserCreated: boolean;
  userCreateUpdateRequestState: RequestStatus;
}

export const initialState: UserCreateState = {
  isUserCreated: false,

  userCreateUpdateRequestState: {
    isLoading: false,
    isSuccessful: false,
    isFailed: false,
  },
};

export function UserCreate(state = initialState, action: any) {
  switch (action.type) {
    case "user-create-update-is-user-created":
      return {
        ...state,
        isUserCreated: action.isUserCreated,
      };
    case "user-create-update-info-request-state":
      return {
        ...state,
        userCreateUpdateRequestState: action.requestState,
      };
    case "user-create-clear":
      return initialState;
    default:
      return state;
  }
}
