import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import useCustomSizing from "src/hooks/useCustomSizing";

type PayoutCategoryProps = {
  text: string;
  onClick: () => void;
  isSelected: boolean;
};

const PayoutCategory = ({
  text = "",
  onClick = () => {},
  isSelected = false,
}: PayoutCategoryProps) => {
  const { getHeightInPixels } = useCustomSizing();

  return (
    <Flex
      flex={1}
      onClick={onClick}
      cursor="pointer"
      alignItems={"center"}
      justify="center"
      py={getHeightInPixels(1.4)}
      boxShadow="sm"
      bgColor={isSelected ? "#ff0063" : "white"}
      fontWeight={isSelected ? "semibold" : "normal"}
      fontSize={getHeightInPixels(2.3)}
      color={isSelected ? "white" : "gray.500"}
    >
      {text ?? <Skeleton />}
    </Flex>
  );
};

export default PayoutCategory;
