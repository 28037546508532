import { UserWallet } from 'src/redux/User/reducer';
import { RequestStatus } from 'src/types/Request';

export interface PayoutOptionCategory {
  category: string;
  name: string;
  payoutOptions: PayoutOption[];
}

export interface PayoutOption {
  value: number;
  currency: string;
  category: string;
  coins: number;
  imageUrl: string;
}

export interface PayoutState {
  payoutOptionCategories: PayoutOptionCategory[];
  fetchPayoutOptionsRequestState: RequestStatus;
}

export const initialState: PayoutState = {
  payoutOptionCategories: [],
  fetchPayoutOptionsRequestState: {
    isSuccessful: false,
    isFailed: false,
    isLoading: false,
  },
};

export function Payout(state = initialState, action: any) {
  switch (action.type) {
    case 'payout-update-payout-options':
      return {
        ...state,
        payoutOptionCategories: action.payoutOptionCategories,
      };
    case 'payout-update-fetch-payout-options-request-state':
      return {
        ...state,
        fetchPayoutOptionsRequestState: action.requestState,
      };
    default:
      return state;
  }
}

export const GetNextAvailableCoinsPayout = (
  wallet: UserWallet | null,
  payoutOptions: PayoutOption[],
): PayoutOption | null => {
  if (!wallet) {
    return null;
  }
  let nextOption: PayoutOption | null = null;

  payoutOptions = payoutOptions.sort((a, b) => (a.coins > b.coins ? 1 : 0));

  payoutOptions.forEach(o => {
    if (o.coins > wallet.coins && !nextOption) {
      nextOption = o;
    }
  });

  return nextOption;
};
