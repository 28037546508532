import {
  Flex,
  GridItem,
  Heading,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PayoutOptionCard from "src/components/PayoutOptionCard";
import PayoutCategory from "src/components/PayoutCategory";
import { RootReducer } from "src/redux";
import { PayoutOptionCategory } from "src/redux/Payout/reducer";
import { FetchPayoutOptions } from "src/redux/Payout/action";
import { FetchPayoutHistory } from "src/redux/PayoutHistory/action";
import PayoutHistoryFAB from "src/components/FAB/PayoutHistoryFAB";
import { CloseIcon } from "@chakra-ui/icons";
import PayoutHistoryItem from "src/components/PayoutHistoryItem";
import useCustomSizing from "src/hooks/useCustomSizing";
import { Link } from "react-router-dom";

const Payout = () => {
  const { getHeightInPixels, getWidthInPixels } = useCustomSizing();

  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const payouts = useSelector(
    (state: RootReducer) => state.payout.payoutOptionCategories
  );
  const payoutHistory = useSelector(
    (state: RootReducer) => state.payoutHistory.payoutHistory
  );
  const userId = useSelector((state: RootReducer) => state.user.userId);

  const wallet = useSelector((state: RootReducer) => state.user.wallet);
  const userCoins = wallet ? wallet.coins : 0;

  const [selectedPayoutCategory, setSelectedPayoutCategory] =
    useState<PayoutOptionCategory>();

  const onPayoutCategoryClick = useCallback(
    (category: PayoutOptionCategory) => {
      setSelectedPayoutCategory(category);
    },
    []
  );

  useEffect(() => {
    setSelectedPayoutCategory(payouts[0]);
  }, [payouts]);

  useEffect(() => {
    dispatch(FetchPayoutOptions());
    dispatch(FetchPayoutHistory());
  }, [dispatch]);

  const emptyHistory = useCallback(() => {
    return (
      <Flex align={"center"} justify={"center"} width={"full"}>
        <Text mb={4} textAlign={"center"} fontWeight={"semibold"}>
          {t("payout.empty_payout_history")}
        </Text>
      </Flex>
    );
  }, []);

  return (
    <Flex
      flex={1}
      width={getWidthInPixels(100)}
      flexDir="column"
      overflow={"hidden"}
      position="relative"
      backgroundColor="white"
    >
      <PayoutHistoryFAB onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          mx={6}
          h="50vh"
          borderRadius={10}
          overflow={"scroll"}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <ModalHeader
            bgColor={"gray.100"}
            borderTopLeftRadius={10}
            borderTopRightRadius={10}
            pos="relative"
          >
            <Heading color={"gray.700"} fontSize={24} fontWeight={"medium"}>
              {t("payout.your_earnings")}
              {" 💰"}
            </Heading>
            <CloseIcon
              onClick={onClose}
              pos={"absolute"}
              right={4}
              top={"24px"}
              color="gray.700"
              boxSize={"16px"}
            />
          </ModalHeader>
          <Flex flexDir={"column"} p={4} alignItems="center" flex={1}>
            {payoutHistory.length < 1
              ? emptyHistory()
              : payoutHistory.map((_itm, idx) => (
                  <PayoutHistoryItem
                    key={`payout history item - ${userId} - ${idx}`}
                    item={_itm}
                  />
                ))}
            <Flex flexDir={"column"} flexWrap="wrap" alignItems={"center"}>
              <a
                href={`mailto:${"help@thespotlight.pro"}?subject=${t(
                  "payout.help_email_subject"
                )} #3${userId ? userId : ""}7&body=${t(
                  "payout.help_email_body"
                )} `}
              >
                <Text textAlign={"center"}>{t("payout.need_help")} </Text>
                <Text textAlign={"center"} textDecor={"underline"}>
                  help@thespotlight.pro
                </Text>
              </a>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
      <Flex w={"100%"} boxShadow="md" mb={getHeightInPixels(0.5)}>
        {payouts.map((opt) => (
          <PayoutCategory
            key={opt.category}
            text={opt.name}
            onClick={() => onPayoutCategoryClick(opt)}
            isSelected={selectedPayoutCategory?.category === opt.category}
          />
        ))}
      </Flex>
      <SimpleGrid
        alignContent={"flex-start"}
        justifyItems="center"
        columns={2}
        flex={1}
        gap={2}
        padding={2}
        pb={getHeightInPixels(16)}
        overflow={"scroll"}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {selectedPayoutCategory?.payoutOptions.map((opt) => (
          <PayoutOptionCard
            key={`${opt.category} * ${opt.coins} option`}
            opt={opt}
            userCoins={userCoins}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default Payout;
